import axios from 'axios';

export interface WindowWithEnv extends Window {
    __ENV?: {
      backendURL: string, // REACT_APP_BACKEND_URL environment variable
      sandbox: "true" | "false", // REACT_APP_SANDBOX_SDK environment variable - string, not boolean!
      googleApi: string, // REACT_APP_GOOGLE_API environment variable
    }
}
// const _window: WindowWithEnv = window;
export const backendURL = process.env.NEXT_PUBLIC_API

export const axiosClient = axios.create({ baseURL: `${backendURL}`, timeout: 200000});

export const configHeader = {responseType: 'arraybuffer', headers: {'Content-Type': 'application/pdf'}};
