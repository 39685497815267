import { Flex, HelpIconCcqIcon2, Text } from "@pancakeswap/uikit";
import { LightTooltip3 } from "components/ToolTip3";
import { UNIT_DATE } from "config";
import React from "react";
import { Items } from "state/MarketPlace/type";
import styled from "styled-components";

interface RowProps {
    buyAmount: number;
    itemSell: Items;
}

const RowMobile: React.FC<React.PropsWithChildren<RowProps>> = ({ buyAmount, itemSell }) => {
    return (
        <WrapItemMobile>
            <Flex justifyContent="space-between" alignItems="center">

                <CsFlexHeader>
                    <LightTooltip3 title="Mệnh giá">
                        <Flex style={{ cursor: 'pointer' }}>
                            <Text mr='4px'>
                                {Number(itemSell.price).toLocaleString("en", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                })}
                            </Text>
                            <HelpIconCcqIcon2 />
                        </Flex>
                    </LightTooltip3>
                </CsFlexHeader>

                <CsFlexHeader>
                    <LightTooltip3 title="Ngày trả lợi suất kế tiếp">
                        <Flex style={{ cursor: 'pointer' }}>
                            <Text mr='4px' ml="2px">
                                {itemSell.paydayNextProfit}
                            </Text>
                            <HelpIconCcqIcon2 />
                        </Flex>
                    </LightTooltip3>
                </CsFlexHeader>

                <CsFlexHeader>
                    <LightTooltip3 title="Đơn giá">
                        <Flex style={{ cursor: 'pointer' }}>
                            <Text mr='4px' ml="2px">
                                {Number(itemSell?.unitPrice).toLocaleString("en", {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0
                                })}
                            </Text>
                            <HelpIconCcqIcon2 />
                        </Flex>
                    </LightTooltip3>
                </CsFlexHeader>
            </Flex>

            <Flex justifyContent="space-between" alignItems="center" mt="1rem">

                <CsFlexHeader>
                    <LightTooltip3 title="Ngày còn lại">
                        <Flex style={{ cursor: 'pointer' }}>
                            <Text mr='4px' ml="2px">
                                {`${itemSell.dayDiff} ${UNIT_DATE}`}
                            </Text>
                            <HelpIconCcqIcon2 />
                        </Flex>
                    </LightTooltip3>
                </CsFlexHeader>

                <CsFlexHeader>
                    <LightTooltip3 title="Kỳ trả lợi suất">
                        <Flex style={{ cursor: 'pointer' }}>
                            <Text mr='4px' ml="2px">
                                {itemSell?.interVestTerm} tháng
                            </Text>
                            <HelpIconCcqIcon2 />
                        </Flex>
                    </LightTooltip3>
                </CsFlexHeader>

                <CsFlexHeader>
                    <LightTooltip3 title="Lợi suất">
                        <Flex style={{ cursor: 'pointer' }}>
                            <Text mr='4px' ml="2px">
                                {itemSell?.interVestTermRate}%
                            </Text>
                            <HelpIconCcqIcon2 />
                        </Flex>
                    </LightTooltip3>
                </CsFlexHeader>
            </Flex>
        </WrapItemMobile>
    );
};

export default RowMobile;

const WrapItemMobile = styled.div`
    padding: 12px;
    margin-top: 16px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.background};
    ${Text} {
        font-size: 12px;
        font-weight: 600;
    }
`;

const CsFlexHeader = styled(Flex)`
    max-width: 100%;
    align-items: center;
    justify-content: center;
`
