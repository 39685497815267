import { Box, Button, Flex, Modal, Text } from "@pancakeswap/uikit";
import styled from "styled-components";

export const CsBox = styled(Box)`
    width: 45%;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;

export const CsFlex = styled(Flex)`
    justify-content: space-between;
    margin-top: 4rem;
    @media screen and (max-width: 1000px) {
        flex-direction: column;
        margin-top: 2rem;
    }
`;

export const CsText = styled(Text)<{ isBuy: boolean }>`
    color: ${({ theme, isBuy }) => (isBuy ? theme.colors.success2 : theme.colors.red)};
    font-weight: 700;
    text-align: right;
`;

export const CsTitleModal = styled(Text)`
    font-size: 34px;
    @media screen and (max-width: 586px) {
        font-size: 24px;
    }
`;
export const CsFlexAgree = styled(Flex)`
    width: 100%;
    margin-top: 1.5rem;
    align-items: center;
    margin-bottom: 1.5rem;
    flex-direction: column;
    ${Text} {
        text-decoration: underline;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.5;
        color: ${({ theme }) => theme.colors.text};
        @media screen and (max-width: 586px) {
            font-size: 14px;
        }
    }
`;
export const CsButtonConfirm = styled(Button)`
    height: 65px;
    font-size: 20px;
    font-weight: 700;
    min-width: 210px;
    @media screen and (max-width: 768px) {
        font-size: 14px;
        line-height: 1.5;
        height: 40px;
        min-width: 112px;
        border-radius: 4px;
    }
`;
export const CsLink = styled.a`
    text-decoration: underline;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.primaryBright};
    @media screen and (max-width: 586px) {
        font-size: 14px;
    }
`;
export const TextAddr = styled(Text)`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.textSubtle};
`;
export const WrapAddress = styled.div`
    height: 40px;
    min-width: 220px;
    margin-left: 40px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    @media screen and (max-width: 1024px) {
        min-width: 120px;
    }
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 40px;
        color: ${({ theme }) => theme.colors.text};
    }
`;
export const CsFlexWrapInfo = styled(Flex)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 4rem;
    @media screen and (max-width: 1024px) {
        margin-top: 2rem;
        flex-direction: column;
    }
`;
export const CSInfoSide = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    width: 40%;
    ${Text} {
        font-size: 16px;
        @media screen and (max-width: 1024px) {
            font-size: 12px;
        }
    }
    @media screen and (max-width: 1024px) {
        width: 100%;
    }
`;

export const WrapperCard = styled(Flex)`
    width: 100%;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.background};
    &:hover {
        background: #f5f5f58a;
    }
    ${Text} {
        line-height: 50px;
        text-align: center;
        padding: 10px 0;
    }
`;
export const Container = styled.div<{ isHaving?: boolean; background?: string }>`
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const WrapperItemHeader = styled(Flex)`
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border-radius: 20px;
    margin: 48px 0 24px 0;
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.colors.textSubtle};
    }
    @media screen and (max-width: 600px) {
        padding: 2rem 25px;
    }
`;
export const WrapItemMobile = styled.div`
    padding: 12px;
    margin-top: 16px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.white};
    @media screen and (max-width: 600px) {
        padding: 10px;
    }
    ${Text} {
        font-size: 12px;
        &:first-child {
            font-weight: 500;
            color: ${({ theme }) => theme.colors.textSubtle};
        }
    }
`;
export const CsModal = styled(Modal)`
    padding: 0;
    min-width: 990px;
    @media screen and (max-width: 1024px) {
        min-width: 90%;
        padding: 0;
    }
`;
export const CsButtonTitle = styled(Text)`
    color: ${({ theme }) => theme.colors.text};
    font-size: 34px;
    font-weight: 700;
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
`;

export const CsButtonCancel = styled(Button)`
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    height: 65px;
    font-size: 20px;
    font-weight: 700;
    min-width: 210px;
    border-radius: 12px;
    color: ${({ theme }) => theme.colors.primary};
    @media screen and (max-width: 768px) {
        height: 40px;
        font-size: 14px;
        min-width: 120px;
        border-radius: 4px;
    }
`;
export const TextTitle = styled(Text)`
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: ${({ theme }) => theme.colors.text};
    @media screen and (max-width: 1000px) {
        font-size: 24px;
        letter-spacing: 0;
    }
`;
export const CsTextMobile = styled(Text)`
    font-size: 16px;
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
    }
    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
`;

export const CustomLink = styled(Flex)`
    justify-content: center;
    align-items: center;
    cursor: pointer;
    ${Text} {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.text};
        @media screen and (min-width: 601px) and (max-width: 1000px) {
            font-size: 14px;
        }
        @media screen and (max-width: 600px) {
            font-size: 12px;
            font-weight: 600;
        }
    }
`;
