import { useDispatch, useSelector } from 'react-redux'
import { useSlowRefreshEffect } from 'hooks/useRefreshEffect';
import { fetchDataOnMarketplace, fetchStatusLoading, fetchTotalSellItem, fetchDataUserAction } from './actions';
import { fetchGetTotalItems, fetchItems, fetchNftAddressItem, fetchDataAllowance } from './fetchData';
import { AppDispatch, AppState } from '../index'



export const GetValueSearch = () => {
    const data = useSelector<AppState, AppState['marketplace']>((state) => state.marketplace)
    const valueSearch = data
    return valueSearch
}

export const UseCoreMarketplace = (contractMarketplaceAddress: string, tokenAddress: string, chainId: number, account: string) => {
    const dispatch = useDispatch<AppDispatch>();
    const { listCart } = GetDataMarketplace(); // run dispatch buy item request

    useSlowRefreshEffect(() => {
        const getDataItem = async () => {
            try {
                dispatch(fetchStatusLoading({ isLoading: true }));
                const dataAllowance = await fetchDataAllowance(contractMarketplaceAddress, tokenAddress, account, chainId);
                const getTotalItems = await fetchGetTotalItems(contractMarketplaceAddress, chainId);
                const items = await fetchItems(contractMarketplaceAddress, chainId, getTotalItems);
                const nftAddressItem = await fetchNftAddressItem(items, contractMarketplaceAddress, chainId);
                dispatch(fetchDataOnMarketplace({ listItem: nftAddressItem }));
                dispatch(fetchDataUserAction({ dataUser: dataAllowance }));
            } catch (e) {
                console.log(e)
            }
        }
        getDataItem()
    }, [account, chainId, contractMarketplaceAddress, dispatch, tokenAddress, listCart.length])

};

export const GetDataMarketplace = () => {
    const dataMarketplace = useSelector<AppState, AppState['marketplace']>((state) => state.marketplace)
    return dataMarketplace
}