import { useTranslation } from "@pancakeswap/localization";
import { useToast } from "@pancakeswap/uikit";
import { useCallWithMarketGasPrice } from "hooks/useCallWithMarketGasPrice";
import { useWrapped } from "hooks/useContract";
// import { usePiBMarketplace } from 'hooks/useContract'
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";

import { ToastDescriptionWithTx } from "components/Toast";
import { useUserRejected } from "hooks/useUserRejected";
import { actionDispatchAssetMessage } from "state/asset/actions";


export const useApproveRedeem = (
    nftAddress: string,
    account: string,
    chainId: number
) => {
    const { t } = useTranslation();
    const { handleRejected } = useUserRejected();
    const [requestedApproveRedeem, setRequestApproveRedeem] = useState(false);
    const { toastSuccess, toastError } = useToast();
    const { callWithMarketGasPrice } = useCallWithMarketGasPrice();
    const [pendingApproveRedeem, setPenddingApproveRedeem] = useState(false);
    const contractWrapped = useWrapped(nftAddress);
    const dispatch = useDispatch<AppDispatch>();
    const handleApproveRedeem = useCallback(async () => {
        setPenddingApproveRedeem(true);
        try {
            const tx = await callWithMarketGasPrice(contractWrapped, "setApprovalForAll", [nftAddress, true]);
            const receipt = await tx.wait();
            if (receipt.status) {
                toastSuccess(t("Chấp thuận thành công"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
                setRequestApproveRedeem(true);
                dispatch(actionDispatchAssetMessage({ messageHash: receipt.transactionHash }));

            } else {
                // user rejected tx or didn't go thru
                toastError(
                    t("Đã có lỗi xảy ra"),
                    t("Xác nhận giao dịch, và đảm bảo rằng bạn đủ token trong ví để xử lý giao dịch!")
                );
            }
        } catch (e) {
            handleRejected(e);
        }
    }, [callWithMarketGasPrice, contractWrapped, dispatch, handleRejected, nftAddress, t, toastError, toastSuccess]);
    return { handleApproveRedeem, requestedApproveRedeem, pendingApproveRedeem };
};
