import BigNumber from "bignumber.js"
import ccqWrappedAbi from "config/abi/ccqWrappedAbi.json"
import { ERC20_ABI } from "config/abi/erc20"
import { BASE_RATE_INTERVEST } from "config/constants"
import { ccqSupportItems } from "config/constants/ccqSupport/constants/type"
import moment from "moment"
import multicall from "utils/multicall"
import { Items } from "state/Order/type"

import { UNITDIFF } from "config"
import {
    AssetItems, DataBalance, DataUser, IntervestRate, ListIntervestRate, ListUserAsset,
    ListUserBalance, ListWrapppedItems,
    WrappedItems
} from "./type"

export const fetchDataAssetItems = async (ccqConfig: ccqSupportItems[], account: string, chainId: number): Promise<ListWrapppedItems> => {
    try {
        if (account?.length > 0) {
            const calls = [];
            for (let index = 0; index < ccqConfig?.length; index++) {
                calls.push({
                    address: ccqConfig[index]?.ccqWrrapped?.address,
                    name: 'etfInfor',
                    params: []
                })
            }
            const result = await multicall(ccqWrappedAbi, calls, chainId);
            const listItems = []
            if (result?.length > 0) {
                for (let index = 0; index < result?.length; index++) {
                    listItems.push({
                        nftAddress: ccqConfig[index]?.ccqWrrapped?.address,
                        expireDate: Number(new BigNumber(result[index]?.expireDate.toString()).toString()),
                        intervestTerm: Number(new BigNumber(result[index]?.intervestTerm.toString()).toString()),
                        issueDate: Number(new BigNumber(result[index]?.issueDate.toString()).toString()),
                        ccqName: result[index]?.name,
                        denominations: Number(new BigNumber(result[index]?.price.toString()).dividedBy(1E18).toString()),
                        publisher: result[index]?.publisher,
                        totalSupply: Number(new BigNumber(result[index]?.totalSupply.toString()).toString()),
                    })
                }
            }
            return {
                listWrappedItem: listItems
            }
        }
        return {
            listWrappedItem: []
        }
    }
    catch (error) {
        console.log(error)
        return {
            listWrappedItem: []
        }
    }
}


export const fetchDataUser = async (ccqConfig: ccqSupportItems[], account: string, chainId: number): Promise<DataUser> => {
    try {
        const fetchApproved = async () => {
            if (account?.length > 0) {
                const callsAllowance = []
                for (let index = 0; index < ccqConfig?.length; index++) {
                    callsAllowance.push(
                        {
                            address: ccqConfig[index]?.ccqWrrapped?.address,
                            name: 'isApprovedForAll',
                            params: [account, ccqConfig[index]?.ccqWrrapped?.address]
                        }
                    )
                }
                const resultAllowance = await multicall(ccqWrappedAbi, callsAllowance, chainId)
                return resultAllowance
            }
            return null
        }
        const fetchBalance = async () => {
            if (account?.length > 0) {
                const callsBalance = []
                for (let index = 0; index < ccqConfig?.length; index++) {
                    callsBalance.push(
                        {
                            address: ccqConfig[index]?.ccqWrrapped?.address,
                            name: 'balanceOf',
                            params: [account, ccqConfig[index]?.ccqWrrapped?.address]
                        }
                    )
                }
                const resultBalance = await multicall(ccqWrappedAbi, callsBalance, chainId)
                return resultBalance
            }
            return null
        }
        const [resultApproved, resultBalance] = await Promise.all([
            fetchApproved(),
            fetchBalance()
        ])
        const result = []
        if (resultApproved !== null && resultBalance !== null) {
            for (let index = 0; index < ccqConfig?.length; index++) {
                result.push(
                    {
                        nftAddress: ccqConfig[index]?.ccqWrrapped?.address,
                        isApproved: false,
                        balance: "0",
                    }
                )
            }
        }
        return {
            dataUser: []
        }
    }
    catch (error) {
        console.log(error)
        return {
            dataUser: []
        }
    }
}

export const fetchIntervestTermRate = async (ccqConfig: ccqSupportItems[], chainId: number): Promise<ListIntervestRate> => {
    try {
        if (ccqConfig?.length > 0) {
            const callsIntervest = []
            for (let index = 0; index < ccqConfig?.length; index++) {
                callsIntervest.push(
                    {
                        address: ccqConfig[index]?.ccqWrrapped?.address,
                        name: 'intervestTermRate',
                        params: []
                    }
                )
            }
            const resultIntervest = await multicall(ccqWrappedAbi, callsIntervest, chainId)
            const result = []
            if (resultIntervest?.length) {
                for (let index = 0; index < resultIntervest?.length; index++) {
                    result.push({
                        intervest: new BigNumber(resultIntervest[index].toString()).dividedBy(BASE_RATE_INTERVEST).multipliedBy(10).toString(),
                        nftAddress: ccqConfig[index]?.ccqWrrapped?.address,
                    })
                }
            }
            return {
                listIntervestRate: result
            }
        }
        return {
            listIntervestRate: []
        }
    } catch (error) {
        return {
            listIntervestRate: []
        }
    }
}

export const fetchUserAssest = async (ccqConfig: ccqSupportItems[], account: string, chainId: number): Promise<ListUserAsset> => {
    try {
        if (account?.length > 0) {
            const calls = [];
            for (let index = 0; index < ccqConfig?.length; index++) {
                calls.push({
                    address: ccqConfig[index]?.ccqWrrapped?.address,
                    name: 'getMyAsset',
                    params: [account]
                })
            }
            const result = await multicall(ccqWrappedAbi, calls, chainId);
            const data = []
            for (let index = 0; index < result?.length; index++) {
                for (let subIndex = 0; subIndex < result[index][0]?.length; subIndex++) {
                    data.push(
                        {
                            nftAddress: ccqConfig[index]?.ccqWrrapped?.address,
                            nftId: Number(new BigNumber(result[index][0][subIndex]?.toString()).toString())
                        }
                    )
                }
            }
            return {
                listUserAsset: data
            }
        }
        return {
            listUserAsset: []
        }
    }
    catch (error) {
        console.log(error)
        return {
            listUserAsset: []
        }
    }
}

export const fetchUserBalance = async (listUserAsset: AssetItems[], listWrappedItem: WrappedItems[], listIntervestRate: IntervestRate[], account: string, chainId: number, listOrder: Items[]): Promise<ListUserBalance> => {
    const currTime = Date.now();
    try {
        const getBalance = async () => {
            if (account?.length > 0) {
                const calls = [];
                for (let index = 0; index < listUserAsset?.length; index++) {
                    calls.push({
                        address: listUserAsset[index]?.nftAddress,
                        name: 'balanceOf',
                        params: [account, listUserAsset[index]?.nftId]
                    })
                }
                const result = await multicall(ccqWrappedAbi, calls, chainId);
                return result
            }
            return null
        }
        const getApproveForAll = async () => {
            if (account?.length > 0) {
                const calls = [];
                for (let index = 0; index < listUserAsset?.length; index++) {
                    calls.push({
                        address: listUserAsset[index]?.nftAddress,
                        name: 'isApprovedForAll',
                        params: [account, listUserAsset[index]?.nftAddress]
                    })
                }
                const result = await multicall(ccqWrappedAbi, calls, chainId);
                return result
            }
            return null
        }
        const getVestingMap = async () => {
            if (account?.length > 0) {
                const calls = [];
                for (let index = 0; index < listUserAsset?.length; index++) {
                    calls.push({
                        address: listUserAsset[index]?.nftAddress,
                        name: 'getMyVestList',
                        params: [account, listUserAsset[index]?.nftId]
                    })
                }
                const result = await multicall(ccqWrappedAbi, calls, chainId);
                return result
            }
            return null
        }
        const getPaused = async () => {
            const calls = [];
            for (let index = 0; index < listUserAsset?.length; index++) {
                calls.push({
                    address: listUserAsset[index]?.nftAddress,
                    name: 'paused',
                    params: []
                })
            }
            const result = await multicall(ccqWrappedAbi, calls, chainId);
            return result
        }
        const getHoldingDate = async () => {
            const calls = listUserAsset.map((item) => ({
                address: item.nftAddress,
                name: "getHoldingDate",
                params: [account, item.nftId]
            }))
            const result = await multicall(ccqWrappedAbi, calls, chainId);
            return result
        };


        const [resultBalance, resultVestingMap, resultApprovedForAll, resultGetPaused, resultGetHoldingDate] = await Promise.all([
            getBalance(),
            getVestingMap(),
            getApproveForAll(),
            getPaused(),
            getHoldingDate()
        ]);

        const data = []
        for (let index = 0; index < resultBalance?.length; index++) {
            const assetItem = listWrappedItem.find((item) => item?.nftAddress?.toLowerCase() === listUserAsset[index]?.nftAddress?.toLowerCase());
            const resultListOrder = listOrder.find((item) => item?.nftAddress?.toLowerCase() === listUserAsset[index]?.nftAddress?.toLowerCase());

            const dataVestingMap = []
            for (let vestingMap = 0; vestingMap < resultVestingMap[index][0]?.length; vestingMap++) {
                dataVestingMap.push(
                    {
                        vestAmount: new BigNumber(resultVestingMap[index][0][vestingMap]?.amount?.toString()).dividedBy(1E18).toString(),
                        isVested: resultVestingMap[index][0][vestingMap]?.isVested,
                        nextInterestPaymentDate: Number(new BigNumber(resultVestingMap[index][0][vestingMap]?.vestDate?.toString()).toString()),
                        intervestPayed: new BigNumber(resultVestingMap[index][0][vestingMap]?.intervestPayed?.toString()).toString(),
                        index: vestingMap
                    }
                )
            }

            const nextVestingMap = dataVestingMap.find((d) => currTime < d.nextInterestPaymentDate * 1000)
            const checkDayDiff = moment(moment(Number(nextVestingMap?.nextInterestPaymentDate) * 1000)).diff(moment(Number(Date.now())), UNITDIFF)

            data.push(
                {
                    nftBalance: new BigNumber(resultBalance[index][0]?.toString()).toNumber(),
                    expireDate: assetItem?.expireDate, // ngày hết hạn
                    intervestTerm: assetItem?.intervestTerm === 92 ? 3 : 6,
                    issueDate: assetItem?.issueDate, // ngày phát hành
                    ccqName: assetItem?.ccqName, // tên ccq
                    denominations: assetItem?.denominations, // number
                    publisher: assetItem?.publisher, // người phát hành
                    totalSupply: assetItem?.totalSupply, // tổng phát hành
                    nftAddress: assetItem?.nftAddress,
                    nftId: listUserAsset[index]?.nftId,
                    isExpire: currTime > assetItem?.expireDate * 1000,
                    profit: assetItem?.intervestTerm === 92 ? 8 : 9.2,
                    vestingMap: dataVestingMap.filter((d) => d?.isVested === false),
                    isApprovedForAll: resultApprovedForAll[index][0],
                    fullVestingMap: dataVestingMap,
                    dayDiff: checkDayDiff + 1 > 92 ? 92 : checkDayDiff + 1,
                    paused: resultGetPaused[index][0],
                    holdingDate: new BigNumber(resultGetHoldingDate[index][0]?.toString()).toNumber(),
                    isOder: !!resultListOrder,
                    orderBy: resultListOrder
                }
            )
        }
        return {
            listUserBalance: data.filter((item) => Number(item?.nftBalance) !== 0)
        }
    } catch (error) {
        return {
            listUserBalance: []
        }
    }
}

export const fetchDataBalanceAccount = async (tokenAddres: string, account: string, chainId: number): Promise<DataBalance> => {
    try {
        const calls = [
            {
                address: tokenAddres,
                name: 'balanceOf',
                params: [account]
            }
        ]
        const [result] = await multicall(ERC20_ABI, calls, chainId)
        return {
            balance: new BigNumber(result?.toString()).toString()
        }
    } catch (error) {
        return {
            balance: "0"
        }
    }
}