import { TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "@pancakeswap/localization";
import {
    ArrowLeftIcon,
    ArrowRIghtIcon,
    AutoRenewIcon,
    Button,
    Flex,
    HelpIconCcqIcon,
    Input,
    InputGroup,
    OpenNewIconFundgo,
    PlayIcon,
    SearchIcon,
    Text,
    useModal
} from "@pancakeswap/uikit";
import SelectCustom from "components/Select/SelectCustom";
import { LightTooltip2 } from "components/ToolTip2";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import moment from "moment";
import React, { useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { useAppDispatch } from "state";
import { GetListHistory, UseCoreListHistory } from "state/history";
import {
    fetchNumberPageCurrent,
    itemHistory,
    selectEndDay,
    selectStartDay,
    selectType
} from "state/history/actions";
import styled from "styled-components";
import { getBlockExploreLink } from "utils";
import { convertDate } from "utils/converDate";
import { renderAddressByType, renderStatus, renderType } from "utils/renderProfit";
import DetailModal from "./components/ModalDetail";
import { CustomLink } from "./styles";

interface OptionProps {
    label: string;
    value: number;
}

const pageSize = 18;
const TabsHistory = ({ windowSize }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { chainId, account } = useActiveWeb3React();
    // history
    const [searchQuery, setSearchQuery] = useState("")
    const dataHistory = GetListHistory();
    const { isLoading } = dataHistory
    const convertStartDate = new Date(dataHistory?.startDay).toISOString()
    const convertEndDate = new Date(dataHistory?.endDay).toISOString()

    UseCoreListHistory(account, pageSize, dataHistory?.pagenumbermkl, convertStartDate, convertEndDate, searchQuery, dataHistory?.type);

    const listHistory = dataHistory?.listItemHistory;

    const filterDataHistory = listHistory.reduce((acc, pre) => {
        const index = acc.findIndex(item =>
            item?.transaction?.toLowerCase() === pre?.transaction?.toLowerCase() &&
            item?.etfContract?.toLowerCase() === pre?.etfContract?.toLowerCase()
        );
        if (index === -1) {
            acc.push(pre);
        } else {
            // eslint-disable-next-line no-param-reassign
            acc[index] = {
                ...acc[index],
                jointsOrderQuantity: acc[index].jointsOrderQuantity + pre.jointsOrderQuantity,
                totalTxValue: acc[index].totalTxValue + pre.totalTxValue
            }
        }
        return acc;
    }, []);

    function handleOpenLink(address, type) {
        window.open(getBlockExploreLink(address, type, chainId));
    }

    function csAddress(dataAddress) {
        if (dataAddress) {
            return `${dataAddress.substring(0, 4)}...${dataAddress.substring(dataAddress.length - 4)}`;
        }
        return "";
    }

    const [openDetailModal] = useModal(<DetailModal handleOpenLink={handleOpenLink} csAddress={csAddress} />);

    const handleStartDate = (event: any) => {
        const newDate = moment(new Date(event)).startOf('day').toISOString()
        dispatch(selectStartDay({ startDay: newDate }));
    };

    const handleEndDate = (event: any) => {
        const newDate = moment(new Date(event)).endOf('day').toISOString()
        dispatch(selectEndDay({ endDay: newDate }));
    };
    const onKeyDown = (e) => {
        e.preventDefault();
    };

    // select sort value
    const handleSelectType = async (option: OptionProps) => {
        await dispatch(fetchNumberPageCurrent({ pagenumbermkl: 0 }))
        await dispatch(selectType({ type: option.value?.toString() }));
    };

    const handleChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(fetchNumberPageCurrent({ pagenumbermkl: 0 }))
        setSearchQuery(event.target.value)
    };

    const handleOpenModalDetail = async (id) => {
        const findItconfirm = filterDataHistory?.find((item) => item?.historyId === id);
        await dispatch(itemHistory({ itemHistory: findItconfirm }));
        openDetailModal();
    };


    const handlePageClick = (event) => {
        dispatch(fetchNumberPageCurrent({ pagenumbermkl: event.selected }))
    };
    const validateMaxDateForStart = useMemo(() => {
        const formatTime = "YYYY-MM-DD HH:mm:ss";
        const newDate = moment(new Date()).format(formatTime);
        const fromDate = moment(new Date(dataHistory?.endDay)).format(formatTime);
        if (moment(newDate).valueOf() > moment(fromDate).valueOf()) return new Date(dataHistory?.endDay)
        return new Date()
    }, [dataHistory?.endDay])
    const pageCount = useMemo(() => {
        return Math.ceil(dataHistory?.totalItem / pageSize)
    }, [dataHistory?.totalItem])
    return (
        <>
            <WrapperTable>
                <CsWrapFilter>
                    <FlexLeft>
                        <CsFlexSort alignItems="center">
                            <Text color="textSubtle" fontSize={["12px", , "14px"]} mb="5px">
                                {t(`Loại lệnh`)}
                            </Text>
                            <SelectCustom
                                options={[
                                    {
                                        label: "Tất cả",
                                        value: ""
                                    },
                                    {
                                        label: "Mua",
                                        value: "buy"
                                    },
                                    {
                                        label: "Bán ngay",
                                        value: "sellNow"
                                    },
                                    {
                                        label: "Bán thoả thuận",
                                        value: "sellP2P"
                                    },
                                ]}
                                onOptionChange={handleSelectType}
                                defaultOptionIndex={0}
                            />
                        </CsFlexSort>
                        <CustomFlex flexDirection="column">
                            <Text mb="5px" color="textSubtle" fontSize={["12px", , "14px"]}>
                                {t("Từ ngày")}
                            </Text>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <CustomCalendar
                                    // label="Date desktop"
                                    inputFormat="DD/MM/YYYY"
                                    value={dataHistory?.startDay}
                                    maxDate={validateMaxDateForStart}
                                    onChange={handleStartDate}
                                    renderInput={(params) => <TextField {...params} onKeyDown={onKeyDown} />}
                                />
                            </LocalizationProvider>
                        </CustomFlex>
                        <CustomFlex flexDirection="column">
                            <Text mb="5px" color="textSubtle" fontSize={["12px", , "14px"]}>
                                {t("Đến ngày")}
                            </Text>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <CustomCalendar
                                    // label="Date desktop"
                                    inputFormat="DD/MM/YYYY"
                                    value={dataHistory.endDay}
                                    minDate={new Date(dataHistory?.startDay)}
                                    maxDate={new Date()}
                                    onChange={handleEndDate}
                                    renderInput={(params) => <TextField {...params} onKeyDown={onKeyDown} />}
                                />
                            </LocalizationProvider>
                        </CustomFlex>
                    </FlexLeft>

                    <FilterControls>
                        <CustomInputGroup startIcon={<SearchIcon color="textDisabled" width="24px" />}>
                            <Input
                                value={searchQuery}
                                placeholder="Tìm kiếm theo mã số giao dịch"
                                onChange={handleChangeSearchQuery}
                            />
                        </CustomInputGroup>
                    </FilterControls>
                </CsWrapFilter>
                {windowSize > 1000 ? (
                    <>
                        <WrapperTableHeader>
                            <Text width="15%">Loại lệnh</Text>
                            <Text width="15%">Mã số giao dịch</Text>
                            <Text width="15%">Ngày giao dịch</Text>
                            <Text width="15%">Số lượng</Text>
                            <CsFlexHeader width="15%">
                                <Text mr='0.2rem'>Tổng giá trị</Text>
                                <LightTooltip2 title="Tổng giá trị giao dịch (VND)">
                                    <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <CsFlexHeader width="15%">
                                <Text mr='0.2rem'>Địa chỉ</Text>
                                <LightTooltip2 title="Địa chỉ ví giao dịch thoả thuận">
                                    <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <Text width="20%">Trạng thái</Text>
                        </WrapperTableHeader>
                        <WrapperTableContent>
                            {filterDataHistory?.length > 0 ? (
                                <>
                                    {filterDataHistory.map((item) => {
                                        const {
                                            historyId,
                                            jointsOrderQuantity,
                                            transaction,
                                            dayTrading,
                                            status,
                                            isSeller,
                                            totalTxValue,
                                            seller,
                                            buyer,
                                            eventType
                                        } = item;
                                        return (
                                            <WrapItem>
                                                <Flex justifyContent="center" width="15%">
                                                    {renderType(isSeller, eventType)}
                                                </Flex>
                                                <CustomLink width="15%"
                                                    onClick={() => handleOpenLink(transaction, "transaction")}
                                                >
                                                    <Text>{csAddress(transaction)}</Text>
                                                    <OpenNewIconFundgo />
                                                </CustomLink>
                                                <Text width="15%">{convertDate(dayTrading)}</Text>
                                                <Text width="15%">{jointsOrderQuantity}</Text>
                                                <Text width="15%">
                                                    {Number(totalTxValue).toLocaleString("en", {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0
                                                    })}
                                                </Text>
                                                <CustomLink
                                                    width="15%"
                                                    onClick={() =>
                                                        handleOpenLink(renderAddressByType(isSeller, isSeller ? buyer : seller, isSeller ? buyer : seller), "address")
                                                    }
                                                >
                                                    <LightTooltip2 title={renderAddressByType(isSeller, isSeller ? buyer : seller, isSeller ? buyer : seller)}>
                                                        <Text>{csAddress(renderAddressByType(isSeller, isSeller ? buyer : seller, isSeller ? buyer : seller))}</Text>
                                                    </LightTooltip2>
                                                    <OpenNewIconFundgo />
                                                </CustomLink>
                                                <WraperBtnSell width="15%">
                                                    {renderStatus(status)}
                                                </WraperBtnSell>
                                            </WrapItem>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    <Text width="100%" textAlign="center" mt="2rem">
                                        Không có dữ liệu
                                    </Text>
                                </>
                            )}
                        </WrapperTableContent>
                    </>
                ) : (
                    <>
                        <WrapperTableMobile>
                            {filterDataHistory?.length > 0 ? (
                                <>
                                    {filterDataHistory?.map((item) => {
                                        const {
                                            historyId,
                                            jointsOrderQuantity,
                                            transaction,
                                            dayTrading,
                                            status,
                                            isSeller,
                                            totalTxValue,
                                            eventType
                                        } = item;
                                        return (
                                            <WrapItemMobile>
                                                <Flex
                                                    width="100%"
                                                    justifyContent="space-between"
                                                    mt="0.5rem"
                                                    alignItems="center"
                                                    flexWrap="wrap"
                                                >
                                                    <Flex justifyContent="left" width="25%" style={{
                                                        position: "relative",
                                                        right: "18px"
                                                    }}>
                                                        <PlayIcon
                                                            onClick={() => handleOpenModalDetail(historyId)}
                                                            width="20px"
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                        {renderType(isSeller, eventType)}
                                                    </Flex>
                                                    <Text width="25%">
                                                        <CustomLink
                                                            onClick={() => handleOpenLink(transaction, "transaction")}
                                                        >
                                                            <Text style={{ width: "65px" }}>{csAddress(transaction)}</Text>
                                                            <OpenNewIconFundgo />
                                                        </CustomLink>
                                                    </Text>
                                                    <Text width="25%" style={{ textAlign: "right" }}>{convertDate(dayTrading)}</Text>
                                                </Flex>
                                                <Flex
                                                    width="100%"
                                                    justifyContent="space-between"
                                                    mt="0.5rem"
                                                    alignItems="center"
                                                    flexWrap="wrap"
                                                >
                                                    <Text width="30%">{jointsOrderQuantity}</Text>
                                                    <Text width="30%" style={{ textAlign: "center" }}>
                                                        {Number(totalTxValue).toLocaleString("en", {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0
                                                        })}
                                                    </Text>
                                                    <Flex width="30%" justifyContent="flex-end">
                                                        {renderStatus(status)}
                                                    </Flex>
                                                </Flex>
                                            </WrapItemMobile>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    <Text width="100%" textAlign="center" mt="2rem">
                                        Không có dữ liệu
                                    </Text>
                                </>
                            )}
                        </WrapperTableMobile>
                    </>
                )}
            </WrapperTable>
            {
                isLoading ?
                    <Flex width="100%" justifyContent="center" mt="3rem">
                        <AutoRenewIcon spin color="textSubtle" />
                    </Flex>
                    :
                    <>
                        {
                            filterDataHistory.length !== 0 ?
                                <CustomPagination width="100%" mt="1rem" justifyContent="center" height="62px">
                                    <ReactPaginate
                                        nextLabel={<ArrowRIghtIcon />}
                                        previousLabel={<ArrowLeftIcon />}
                                        onPageChange={handlePageClick}
                                        pageCount={pageCount}
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                        forcePage={dataHistory?.pagenumbermkl}
                                    />
                                </CustomPagination>
                                : ''
                        }
                    </>
            }
        </>
    );
};

const CsFlexHeader = styled(Flex)`
    max-width: 100%;
    align-items: center;
    justify-content: center;
`

const WrapItemMobile = styled.div`
    padding: 0 12px 12px;
    margin-top: 16px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.background};
    ${Text} {
        font-size: 12px;
        font-weight: 600;
        &:last-child {
            text-align: left;
        }
    }
`;

const WrapperTableMobile = styled(Flex)`
    width: 100%;
    padding-bottom: 20px;
    flex-direction: column;
`;

const CsWrapFilter = styled(Flex)`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 2rem;
    @media only screen and (max-width: 1000px) {
        width: 100%;
        margin-top: 1rem;
        flex-direction: column;
    }
`;
const FlexLeft = styled(Flex)`
    width: 50%;
    gap: 10px;
    justify-content: space-between;
    @media only screen and (max-width: 1000px) {
        width: 100%;
    }
`;
const CustomFlex = styled(Flex)`
    width: 33%;
    @media only screen and (max-width: 1000px) {
    }
`;
const CustomCalendar = styled(DesktopDatePicker)`
    cursor: pointer;
    height: 48px !important;
    width: 180px;
    @media only screen and (max-width: 1024px) {
        width: 160px;
    }
    @media only screen and (max-width: 1000px) {
        width: 100% !important;
        height: 42px !important;
    }
    > div {
        border-radius: 12px !important;
        height: 100% !important;
        width: 100% !important;
        box-sizing: border-box;
        position: relative;
        font-size: 14px;
        @media only screen and (max-width: 1000px) {
            font-size: 12px;
            .MuiInputAdornment-positionEnd {
                button {
                    padding: 8px;
                    font-size: 12px;
                    svg {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 600px) {
        width: 100%;
    }
`;

const WrapperTable = styled.div`
    width: 100%;
`;

const WrapperTableHeader = styled(Flex)`
    width: 100%;
    height: 100%;
    position: relative;
    flex-direction: row;
    border-radius: 20px;
    margin: 40px 0 0px 0;
    padding: 0 26px;
    align-items: center;
    justify-content: space-between;
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.colors.textSubtle};
    }
    @media screen and (max-width: 600px) {
        padding: 2rem 25px;
    }
`;
const WrapperTableContent = styled(Flex)`
    flex-direction: column;
`;

const WrapItem = styled(Flex)`
    width: 100%;
    height: 100%;
    height: 71px;
    padding: 26px;
    align-items: center;
    position: relative;
    flex-direction: row;
    border-radius: 20px;
    margin-top: 24px;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.colors.textSubtle};
    ${Text} {
        text-align: center;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.04em;
    }
    button {
        color: ${({ theme }) => theme.colors.red};
        background: transparent;
        border-radius: 4px;
        font-weight: 700;
        height: 40px;
        width: 143px;
        padding: 0 11px;
        font-size: 14px;
    }
`;
const CsText = styled(Text) <{ isBuy: number }>`
    color: ${({ theme, isBuy }) => (isBuy === 1 ? theme.colors.success2 : theme.colors.red)};
    font-weight: 700;
`;

const CsButton = styled(Button) <{ isSell: boolean }>`
    border: 1px solid ${({ theme }) => theme.colors.red};
`;
const WraperBtnSell = styled(Flex)`
    justify-content: center;
    width: 20%;
`;
const CsFlexSort = styled(Flex)`
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
`;
const FilterControls = styled(Flex)`
    width: 100%;
    flex-wrap: wrap;
    max-width: 450px;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1000px) {
        margin-top: 1rem;
        max-width: 100%;
    }
`;
const CustomInputGroup = styled(InputGroup)`
    border-radius: 12px;
    border: 1px solid transparent;
    background: transparent !important;
    border: 1px solid ${({ theme }) => theme.colors.textSubtle};
    > input {
        background: ${({ theme }) => theme.colors.white} !important;
        border: none;
        border-radius: 0;
        height: 48px;
        font-size: 16px;
        @media screen and (max-width: 1000px) {
            background: transparent !important;
            height: 42px;
            font-size: 14px;
        }
        @media screen and (max-width: 600px) {
            font-size: 12px;
            height: 42px;
            background: transparent !important;
        }
        ::placeholder {
            color: ${({ theme }) => theme.colors.textDisabled};
        }
    }
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;

const CustomPagination = styled(Flex)`
    margin-bottom: 1.5rem;
    .pagination {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        @media screen and (max-width: 600px) {
            width: 100%;
        }
        * {
            list-style-type: none;
        }
    }
    .page-item {
        margin: 0 0.375rem;
        &:first-child,
        &:last-child {
            a {
                &:hover {
                    color: #13486b;
                    border: none !important;
                }
            }
        }
        &:first-child,
        &:last-child {
            a {
                border: none;
                background: none !important;
            }
        }
        &:last-child.disabled,
        &:first-child.disabled {
            cursor: not-allowed;
        }
    }
    .page-link {
        width: 32px;
        height: 32px;
        display: flex;
        background: #fff;
        align-items: center;
        justify-content: center;
        border-radius: 8px !important;
        color: #02111b;
        border: 1px solid #efefef;
        @media screen and (max-width: 768px) {
            padding: 12px;
        }
        &:focus {
            box-shadow: none !important;
        }
        &:hover {
            background: #13486b;
            color: #fff;
            border: 1px solid #13486b !important;
        }
    }
    .page-item.disabled .page-link {
        background: ${({ theme }) => theme.colors.disabled};
        cursor: not-allowed !important;
        opacity: 0.7;
        pointer-events: none;
    }
    .page-item.active .page-link {
        background: #13486b;
        color: #fff;
        border: none;
    }
`;


export default TabsHistory;
