import { useTranslation } from "@pancakeswap/localization";
import { Flex, HelpIconCcqIcon, Text } from "@pancakeswap/uikit";
import GetWindowSize from "@pancakeswap/uikit/src/util/getWindowSize";
import PageFullWidth from "components/Layout/PageFullWidth";
import SubHeader from "components/Layout/SubHeader";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useEffect, useMemo } from "react";
import { GetDataMarketplace, UseCoreMarketplace } from "state/MarketPlace";
import styled from "styled-components";

import { LightTooltip } from "components/ToolTip";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { fetchCartItems } from "state/MarketPlace/actions";
import { renderMarketplaceAddress, renderToken } from "utils/renderAddress";
import Cart from "./Cart";
import Ccq from "./Ccq";
import FooterTabs from "./FooterTabs";
import HeaderSort from "./components/HeaderSort";
import { CsItem, CsItemSmall } from "./style";

const MarketPlace = () => {
    const { t } = useTranslation();
    const windowSize = GetWindowSize();
    const { chainId, account } = useActiveWeb3React();
    const marketplaceAddress = renderMarketplaceAddress(chainId);
    const token = renderToken(chainId);
    UseCoreMarketplace(marketplaceAddress, token.address, chainId, account);
    const dataMarketplace = GetDataMarketplace();
    const { denominations, paymentPeriod, listCart, listItem, dataUser } = dataMarketplace;
    const sortAmount = [...listItem]?.sort((a, b) => a?.amount - b?.amount);

    const filterDataAddress = sortAmount.reduce((acc, pre) => {
        const index = acc.findIndex((item) => item?.nftAddress?.toLowerCase() === pre?.nftAddress?.toLowerCase());
        if (index === -1) {
            acc.push({
                ...pre,
                buys: [
                    {
                        amount: pre.amount,
                        id: pre.id,
                    },
                ]
            });
        } else {
            const item = [...acc[index].buys];
            item.push({
                amount: pre.amount,
                id: pre.id,
            })
            // eslint-disable-next-line no-param-reassign
            acc[index] = {
                ...acc[index],
                amount: acc[index].amount + pre.amount,
                buys: item
            }
        }
        return acc;
    }, []);

    const sortUnitPrice = [...filterDataAddress]?.sort((a, b) => b?.unitPrice - a?.unitPrice)

    const sortByDenominations = useMemo(() => {
        const data = [...sortUnitPrice];
        switch (denominations) {
            case 100000:
                return data.filter((item) => Number(item.price) === 100000);
            default:
                return data;
        }
    }, [denominations, sortUnitPrice]);
    const sortByPaymentPeriod = useMemo(() => {
        const data = [...sortByDenominations];
        switch (paymentPeriod) {
            case 3: // 3 month
                return data.filter((item) => Number(item?.interVestTerm) === 3);
            case 6: //  6 month
                return data.filter((item) => Number(item?.interVestTerm) === 6);
            default:
                return data;
        }
    }, [paymentPeriod, sortByDenominations]);

    const sortByNextInterestPaymentDate = useMemo(() => {
        const data = [...sortByPaymentPeriod];
        switch (dataMarketplace?.nextInterestPaymentDate) {
            case 4:
                return data.filter((item) => item?.dayDiff >= 60);
            case 3:
                return data.filter(
                    (item) =>
                        item?.dayDiff >= 30 &&
                        item?.dayDiff <= 60
                );
            case 2:
                return data.filter(
                    (item) =>
                        item?.dayDiff >= 10 &&
                        item?.dayDiff <= 30
                );
            case 1:
                return data.filter((item) => item?.dayDiff <= 10);
            default:
                return data;
        }
    }, [dataMarketplace?.nextInterestPaymentDate, sortByPaymentPeriod]);

    const sortByCurrentYield = useMemo(() => {
        const data = [...sortByNextInterestPaymentDate];
        switch (dataMarketplace?.currentYield) {
            case 9.2: // 9.2% => 3 months
                return data.filter((item) => Number(item?.interVestTermRate) === 9.2);
            case 8: // 8.2% =>  6 months
                return data.filter((item) => Number(item?.interVestTermRate) === 8);
            default:
                return data;
        }
    }, [dataMarketplace?.currentYield, sortByNextInterestPaymentDate]);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(fetchCartItems({ listCart: [] }))
    }, [dispatch])
    return (
        <CsPageFullWidth>
            <SubHeader listItem={sortByCurrentYield} dataUser={dataUser} account={account} />
            <Flex maxWidth="1280px" width="100%" flexDirection="column" mt={["1rem", , , "4rem"]} height="auto">
                <HeaderSort />
                {windowSize > 1000 && (
                    <>
                        <WrapperTable>
                            <TableMainTitle>
                                <Text width="19%" bold fontSize="20px" textAlign="center">{t("Thông tin CCQ")}</Text>
                                <Text width="45.5%" bold fontSize="20px">{t("Lợi suất")}</Text>
                                <Text width="33.5%" bold fontSize="20px">{t("Thông tin giao dịch")}</Text>
                            </TableMainTitle>
                            <WrapperItemHeader>
                                <CsItemSmall >
                                    <Text mr='0.3rem'>SL</Text>
                                    <LightTooltip title="Số lượng">
                                        <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                    </LightTooltip>
                                </CsItemSmall>
                                <CsItemSmall >
                                    <Text mr='0.3rem'>Mệnh giá</Text>
                                    <LightTooltip title="Mệnh giá (VND)">
                                        <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                    </LightTooltip>
                                </CsItemSmall>
                                <CsItemSmall >
                                    <Text mr='0.3rem'>Kỳ đã nhận</Text>
                                    <LightTooltip title="Kỳ đã nhận/tổng kỳ">
                                        <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                    </LightTooltip>
                                </CsItemSmall>
                                <CsItemSmall >
                                    <Text mr='0.3rem'>Kỳ trả LS</Text>
                                    <LightTooltip title="Kỳ trả lợi suất">
                                        <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                    </LightTooltip>
                                </CsItemSmall>
                                <CsItemSmall >
                                    <Text mr='0.3rem'>Ngày trả LS</Text>
                                    <LightTooltip title="Ngày trả lợi suất kế tiếp">
                                        <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                    </LightTooltip>
                                </CsItemSmall>
                                <CsItemSmall >
                                    <Text mr='0.2rem'>Ngày còn lại</Text>
                                </CsItemSmall>
                                <CsItemSmall >
                                    <Text mr='0.3rem'>LSNCL</Text>
                                    <LightTooltip title="Lợi suất ngày còn lại">
                                        <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                    </LightTooltip>
                                </CsItemSmall>
                                <CsItemSmall >
                                    <Text mr='0.3rem'>Đơn giá</Text>
                                    <LightTooltip title="Đơn giá (VND)">
                                        <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                    </LightTooltip>
                                </CsItemSmall>
                                <CsItem>
                                    <Text mr='0.3rem'>SL mua</Text>
                                    <LightTooltip title="Số lượng mua">
                                        <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                    </LightTooltip>
                                </CsItem>
                                <CsItem />
                            </WrapperItemHeader>
                        </WrapperTable>
                    </>
                )}
                <Cart listItem={filterDataAddress} windowSize={windowSize} listCart={listCart} />
                <Ccq listItem={sortByCurrentYield} windowSize={windowSize} listCart={listCart} />
            </Flex>
            <FooterTabs />
        </CsPageFullWidth>
    );
};
export default MarketPlace;

const WrapperTable = styled.div`
    padding-top: 4rem;
    padding: 0px 6px 0px 6px;
`;
const TableMainTitle = styled(Flex)`
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background: rgba(212, 238, 255, 0.34);
    &::after {
        content: "";
        position: absolute;
        left: 19%;
        width: 1px;
        height: 100%;
        background: ${({ theme }) => theme.colors.border2};
    }
    &::before {
        content: "";
        position: absolute;
        right: 33.5%;
        width: 1px;
        height: 100%;
        background: ${({ theme }) => theme.colors.border2};
    }
    ${Text}{
        text-align:center;
    }
`;
const WrapperItemHeader = styled(Flex)`
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border-radius: 20px;
    margin: 36px 0 26px 0;
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 15px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.colors.textSubtle};
    }
    @media screen and (max-width: 600px) {
        padding: 2rem 25px;
    }
    ${CsItem}{
        line-height: 21px;
        color: ${({ theme }) => theme.colors.textSubtle};
    }

`;

const CsPageFullWidth = styled(PageFullWidth)`
`;
