import { useTranslation } from "@pancakeswap/localization";
import { AutoRenewIcon, Box, Flex, HelpIconCcqIcon, HelpIconCcqIcon2, OpenNewIconFundgo, Skeleton, Text } from "@pancakeswap/uikit";
import GetWindowSize from "@pancakeswap/uikit/src/util/getWindowSize";
import BigNumber from "bignumber.js";
import React, { useMemo, useState } from "react";
import { AllowanceItem, ItemWithVestingMap, Items } from "state/Order/type";
import { convertDate, getDateBetweenTwoDates } from "utils/converDate";
import { UNIT_DATE } from "config";
import { LightTooltip2 } from "components/ToolTip2";
import styled from "styled-components";
import { LightTooltip3 } from "components/ToolTip3";
import { useDownload } from "../../TabsAsset/hook/useDownload";
import { GetNftInfo } from "../hook/fetchData";
import { ItemTransaction } from "./ItemTransaction";
import {
    Container,
    CsButtonCancel,
    CsButtonConfirm,
    CsFlexAgree,
    CsLink,
    CsModal,
    CsMonth,
    CsTitleModal,
    CustomLink,
    WrapItemMobile,
    WrapQuantity,
    WrapperButton,
    WrapperCard,
    WrapperItemHeader,
    CsFlexHeader
} from "./styles";
import { useDelistp2p } from "../hook/useDelist";
import { useBuyP2P } from "../hook/useBuyP2P";
import { useApprove } from "../hook/useApprove";

interface ModalProps {
    title: React.ReactNode;
    hideCloseButton?: boolean;
    onBack?: () => void;
    onDismiss?: () => void;
    bodyPadding?: string;
    headerBackground?: string;
    listOrder?: any;
    sAccount?: (seller) => string;
    handleOpenLink?: (seller) => void;
    item: ItemWithVestingMap,
    account: string,
    chainId: number,
    itemAllowance: AllowanceItem,
    balance: string,
    tokenAddress: string
}

const ConfirmModalPC: React.FC<React.PropsWithChildren<ModalProps>> = ({
    handleOpenLink,
    sAccount,
    title,
    onDismiss,
    item,
    account,
    chainId,
    itemAllowance,
    balance,
    tokenAddress,
    ...props
}) => {
    const { t } = useTranslation();
    const windowSize = GetWindowSize();
    const { nftInfo, isLoading } = GetNftInfo(item?.nftAddress, item?.addressTrading, item?.nftId, account, chainId)
    const isNotBalance = new BigNumber(balance).isLessThan(item?.amountTrading)
    const isNotAllowance = new BigNumber(itemAllowance?.allowance).isLessThan(item?.amountTrading)
    // for download
    const raw = JSON.stringify({
        "buyerWalletAddress": account,
        "sellerWalletAddress": item?.addressTrading,
        "pendingEtf": [
            {
                "numericalOrder": "1",
                "etfId": `${item?.nftId}`,
                "parValue": `${Number(nftInfo?.price).toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 })} VND`,
                "interest": `${Number(nftInfo?.profit) * 100}%`,
                "incomingInterestDate": convertDate(nftInfo?.nextInterestPaymentDate * 1000),
                "releaseDate": convertDate(Number(nftInfo?.issueDate) * 1000),
                "maturityDate": convertDate(Number(nftInfo?.expireDate) * 1000),
                "transferValue": `${Number(item?.price).toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 })} VND`
            }
        ],
        "transferedEtf": [
            {
                "numericalOrder": "1",
                "etfId": `${item?.nftId}`,
                "transferDate": convertDate(Date.now())
            }
        ],
        "transferDate": convertDate(Date.now()),
        "totalTransferValue": `${Number(item?.price).toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 })} VND`
    });
    const { penddingDowload, handleDownload } = useDownload(raw)
    // for reject-confirm
    const { handleDelistp2p, pendingDelistp2p } = useDelistp2p(account, chainId, item?.nftAddress, item?.buys, onDismiss)
    const { handleBuyP2P, pendingBuyP2P } = useBuyP2P(account, chainId, item?.nftAddress, item?.buys, onDismiss)
    const { handleApprove, pendingTx } = useApprove(item?.nftAddress, tokenAddress, account, chainId)

    const totalVested = useMemo(() => {
        return item?.vestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [item?.vestingMap])
    const totalVestingMap = useMemo(() => {
        return item?.vestingMap?.length
    }, [item?.vestingMap?.length])


    return (
        <CsModal title={title} onDismiss={onDismiss} {...props}>
            <Flex width="100%" flexDirection="column" alignItems="center" mt="1rem">
                <CsTitleModal color="text" fontSize="28px" fontWeight="700">
                    {t("Xác nhận giao dịch")}
                </CsTitleModal>
            </Flex>
            <Box>
                {windowSize > 1000 ? (
                    <>
                        <WrapperItemHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Mệnh giá</Text>
                                <LightTooltip2 title="Mệnh giá (VND)">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <Text width="14.2%">Lợi suất</Text>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Kỳ đã nhận</Text>
                                <LightTooltip2 title="Kỳ đã nhận/Tổng kỳ">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Kỳ trả LS</Text>
                                <LightTooltip2 title="Kỳ trả lợi suất">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Ngày trả LS</Text>
                                <LightTooltip2 title="Ngày trả lợi suất">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <Text width="14.2%">Ngày còn lại</Text>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Giá trị</Text>
                                <LightTooltip2 title="Giá trị (VND)">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                        </WrapperItemHeader>
                        <Container>
                            <WrapperCard>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        Number(nftInfo?.price).toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        `${Number(nftInfo?.profit).toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })}%`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {totalVested}/{totalVestingMap}
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        `${nftInfo?.intervestTerm} tháng`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        `${convertDate(nftInfo?.nextInterestPaymentDate * 1000)}`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        `${getDateBetweenTwoDates(nftInfo?.nextInterestPaymentDate * 1000)} ${UNIT_DATE}`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        <>
                                            {new BigNumber(item?.price).isNaN() ?
                                                `0`
                                                :
                                                Number(item?.price).toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                })
                                            }
                                        </>
                                    }
                                </Text>
                            </WrapperCard>
                        </Container>
                        <ItemTransaction
                            titleOrderQuantity="Số lượng giao dịch"
                            valueOrderQuantity={item?.amountTrading?.toString()}
                            valueTransactionMethod="Mua thỏa thuận"
                            dateTransaction={convertDate(item?.dateTrading * 1000)}
                            addressBuy={item?.addressTrading}
                            totalMoney={Number(item?.price)}
                            utiOrderQuantity="CCQ"
                            textAlert="Địa chỉ ví người bán"
                        />
                    </>
                ) : (
                    <>
                        <WrapItemMobile>
                            <WrapQuantity>
                                <Flex
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                                        gap: "8px"
                                    }}
                                >
                                    <CsFlexHeaderMb width="100%" justifyContent="flex-start">
                                        <LightTooltip3 title="Mệnh giá (VND)">
                                            <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                                <Text>
                                                    {isLoading ?
                                                        <Skeleton width="42px" />
                                                        :
                                                        Number(nftInfo?.price).toLocaleString("en", {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0
                                                        })
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </LightTooltip3>
                                    </CsFlexHeaderMb>
                                    <CsFlexHeaderMb width="100%" justifyContent="center">
                                        <LightTooltip3 title="Kỳ đã nhận/Tổng kỳ">
                                            <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                                <Text>
                                                    {totalVested}/{totalVestingMap}
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </LightTooltip3>
                                    </CsFlexHeaderMb>
                                    {/* <CsFlexHeaderMb width="100%" justifyContent="flex-start">
                                        <Text color="textDisabled">SL: {' '}</Text>
                                        <LightTooltip3 title="Số lượng giao dịch">
                                            <Flex style={{cursor: 'pointer', gap: '4px'}}>
                                                <Text ml="4px">
                                                { isLoading ?
                                                    `0`
                                                :
                                                    `${item?.amountTrading}`
                                                }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </LightTooltip3>
                                    </CsFlexHeaderMb> */}
                                    <CsFlexHeaderMb width="100%" justifyContent="center">
                                        <LightTooltip3 title="Lợi suất">
                                            <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                                <Text>
                                                    {isLoading ?
                                                        <Skeleton width="42px" />
                                                        :
                                                        `${nftInfo?.profit}%`
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </LightTooltip3>
                                    </CsFlexHeaderMb>
                                    {/* <CsFlexHeaderMb width="100%" justifyContent="flex-end">
                                        <LightTooltip3 title="Giá trị (VNDC)">
                                            <Flex style={{cursor: 'pointer', gap: '4px'}}>
                                                <Text>
                                                    { isLoading ?
                                                        <Skeleton width="42px"/>
                                                    :
                                                        Number(item?.price).toLocaleString("en", {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0
                                                        })
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </LightTooltip3>
                                    </CsFlexHeaderMb> */}
                                    {/* <Text style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                                        { isLoading ?
                                            <Skeleton width="42px"/>
                                        :
                                            Number(item?.price).toLocaleString("en", {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0
                                            })
                                        }
                                    </Text> */}
                                </Flex>
                                <Flex
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                                        gap: "8px",
                                        marginTop: "8px"
                                    }}
                                >
                                    <CsFlexHeaderMb width="100%" justifyContent="flex-start">
                                        <LightTooltip3 title="Kỳ trả lợi suất">
                                            <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                                <CsMonth>
                                                    <span>
                                                        {isLoading ?
                                                            <Skeleton width="42px" />
                                                            :
                                                            `${nftInfo?.intervestTerm} tháng`
                                                        }
                                                    </span>
                                                </CsMonth>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </LightTooltip3>
                                    </CsFlexHeaderMb>
                                    <CsFlexHeaderMb width="100%" justifyContent="center">
                                        <LightTooltip3 title="Ngày còn lại">
                                            <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                                {isLoading ?
                                                    <Skeleton width="42px" />
                                                    :
                                                    <Text bold>{getDateBetweenTwoDates(nftInfo?.nextInterestPaymentDate * 1000)} {UNIT_DATE}</Text>
                                                }
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </LightTooltip3>
                                    </CsFlexHeaderMb>
                                    <CsFlexHeaderMb width="100%" justifyContent="center">
                                        <LightTooltip3 title="Ngày trả lợi suất">
                                            <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                                <Text>
                                                    {isLoading ?
                                                        <Skeleton width="42px" />
                                                        :
                                                        `${convertDate(nftInfo?.nextInterestPaymentDate * 1000)}`
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </LightTooltip3>
                                    </CsFlexHeaderMb>
                                </Flex>
                            </WrapQuantity>
                            <Text style={{ color: "#02111b", fontWeight: 700, fontSize: "0.875rem" }}>
                                Thông tin chứng chỉ quỹ
                            </Text>
                            <Flex width="100%" justifyContent="space-between" alignItems="center" mt="1rem">
                                <Text color="textSubtle">Ngày giao dịch</Text>
                                <Text bold>{convertDate(item?.dateTrading * 1000)}</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="space-between" alignItems="center" mt="1.313rem">
                                <Text color="textSubtle">Địa chỉ ví người bán</Text>
                                <CustomLink
                                    style={{ textAlign: "right" }}
                                    onClick={() => handleOpenLink(item?.addressTrading)}
                                >
                                    <Text style={{ color: "#02111b", fontWeight: "bold" }}>
                                        {sAccount(item?.addressTrading)}
                                    </Text>
                                    <OpenNewIconFundgo />
                                </CustomLink>
                            </Flex>
                            <Flex width="100%" justifyContent="space-between" alignItems="center" mt="1.313rem">
                                <Text color="textSubtle">Số lượng giao dịch (CCQ)</Text>
                                <Text>{item?.amountTrading}</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="space-between" alignItems="center" mt="1.313rem">
                                <Text color="textSubtle">Phương thức bán </Text>
                                <Text>Bán thỏa thuận</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="space-between" alignItems="center" mt="1.313rem">
                                <Text color="textSubtle">Tổng tiền thỏa thuận</Text>
                                <Flex style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <Flex flexDirection="column" alignItems="flex-end">
                                        <Text mb='4px'>
                                            {isLoading ?
                                                <Skeleton width="42px" />
                                                :
                                                Number(item?.price).toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                })
                                            }
                                            <span style={{ marginLeft: "5px" }}>VND</span>
                                        </Text>
                                        {!isLoading && Number(item?.price) !== 0 && <CsUnit style={{ color: "#8C8D8E" }}>
                                            {` (Tương đương ${Number(item?.price).toLocaleString("en", {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0
                                            })} điểm VNDC)`}
                                        </CsUnit>}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </WrapItemMobile>
                    </>
                )}
            </Box>
            <WrapperButton>
                {
                    !item.isBuyP2P &&
                    <Flex
                        style={{
                            textAlign: "center",
                            color: "red",
                            fontSize: "13px",
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "10px",
                        }}
                    >Đã hết kỳ nhận lợi suất của CCQ không thể thực hiện giao dịch, vui lòng xác nhận hủy!</Flex>
                }
                <Flex width="100%" justifyContent="center" style={{ gap: "1.5rem" }}>
                    {isNotAllowance ?
                        <CsButtonConfirm
                            onClick={handleApprove}
                            disabled={pendingTx || isNotBalance || pendingDelistp2p || pendingBuyP2P || item?.paused || !item.isBuyP2P}
                            endIcon={pendingTx ? <AutoRenewIcon spin color="textSubtle" /> : ""}
                        >
                            {t("Chấp thuận")}
                        </CsButtonConfirm>
                        :
                        <CsButtonConfirm
                            disabled={pendingBuyP2P || isNotBalance || pendingDelistp2p || item?.paused || !item.isBuyP2P}
                            onClick={handleBuyP2P}
                            endIcon={pendingBuyP2P ? <AutoRenewIcon spin color="textSubtle" /> : ""}
                        >
                            {t("Xác nhận mua")}
                        </CsButtonConfirm>
                    }
                    <CsButtonCancel
                        disabled={pendingDelistp2p || pendingBuyP2P || item?.paused}
                        onClick={handleDelistp2p}
                        endIcon={pendingDelistp2p ? <AutoRenewIcon spin color="textSubtle" /> : ""}
                    >
                        {t("Xác nhận hủy")}
                    </CsButtonCancel>
                </Flex>
            </WrapperButton>
            {isNotBalance &&
                <Flex width='100%' justifyContent="center" mt="10px">
                    <Text color="primaryBright">Số dư VNDC không đủ</Text>
                </Flex>
            }
            {item?.paused &&
                <Flex width="100%" justifyContent="center" mt={["1rem", , , "1rem"]}>
                    <Text fontSize={["12px", , , "14px"]} color="red">{t("CCQ đang tạm khóa, vui lòng quay lại sau!")}</Text>
                </Flex>
            }
            {
                item.isBuyP2P &&
                <CsFlexAgree>
                    <Text>{t("Bằng cách “Xác nhận mua” tôi đồng ý với")}</Text>
                    <CsLink disabled={penddingDowload} onClick={handleDownload}>{t("hợp đồng chuyển nhượng Chứng chỉ Quỹ ")}</CsLink>
                </CsFlexAgree>
            }
        </CsModal>
    );
};

const CsFlexHeaderMb = styled(Flex)`
    max-width: 100%;
    align-items: center;
    /* justify-content: center; */
`
const CsUnit = styled.span`
    font-size: 12px;
    @media screen and (max-width: 600px) {
        font-size: 10px;
    }
`
export default ConfirmModalPC;
