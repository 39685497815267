import { Flex, OpenNewIconFundgo, Text } from "@pancakeswap/uikit";
import { LightTooltip2 } from "components/ToolTip2";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import styled from "styled-components";
import { getBlockExploreLink } from "utils";

interface ItemTransactionProps {
    titleOrderQuantity: string;
    valueOrderQuantity: string;
    utiOrderQuantity?: string;
    valueTransactionMethod: string;
    dateTransaction: string;
    addressBuy: string;
    totalMoney: number;
    textAlert: string
}
export const ItemTransaction = ({
    titleOrderQuantity,
    valueOrderQuantity,
    utiOrderQuantity,
    valueTransactionMethod,
    dateTransaction,
    addressBuy,
    totalMoney,
    textAlert
}: ItemTransactionProps) => {
    const { chainId } = useActiveWeb3React();

    const handleOpenLink = () => {
        window.open(getBlockExploreLink(addressBuy, "address", chainId));
    };

    function sAccount(dataAddress) {
        if (dataAddress) {
            return `${dataAddress.substring(0, 4)}...${dataAddress.substring(dataAddress.length - 4)}`;
        }
        return "";
    }
    return (
        <TransactionInformation>
            <Item>
                <TextLabel>Thông tin giao dịch</TextLabel>
                <ItemLabel
                    title={titleOrderQuantity}
                    options={
                        <Flex alignItems="center">
                            <Text fontSize="14px" color="primaryBright">{valueOrderQuantity}</Text>
                            {utiOrderQuantity && (
                                <Text color="#02111B" fontSize="14px" fontWeight="600" ml="3px">
                                    {utiOrderQuantity}
                                </Text>
                            )}
                        </Flex>
                    }
                />
                <ItemLabel title="Phương thức giao dịch" value={valueTransactionMethod} />
            </Item>
            <div>
                <ItemLabel title="Ngày giao dịch" value={dateTransaction} />
                <ItemLabel
                    title={textAlert}
                    options={
                        <Flex alignItems="center" style={{ cursor: "pointer" }} onClick={() => handleOpenLink()}>
                            <LightTooltip2 title={addressBuy}>
                                <Text fontSize="14px">{sAccount(addressBuy)}</Text>
                            </LightTooltip2>
                            <OpenNewIconFundgo fontSize="14px" />
                        </Flex>
                    }
                />
                <ItemLabel
                    title="Tổng tiền thỏa thuận"
                    options={
                        <Flex alignItems="flex-end" flexDirection="column">
                            <Flex>
                                <Text color="#F9A138" fontSize="14px">
                                    {Number(totalMoney).toLocaleString("en", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })}
                                </Text>
                                <Text color="#02111B" fontWeight="700" ml="5px" fontSize="14px">
                                    VND
                                </Text>
                            </Flex>
                            {
                                Number(totalMoney) !== 0 && <CsUnit style={{ color: "#8C8D8E" }}>
                                    {` (Tương đương ${Number(totalMoney).toLocaleString("en", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })} điểm VNDC)`}
                                </CsUnit>
                            }
                        </Flex>
                    }
                />
            </div>
        </TransactionInformation>
    );
};

const ItemLabel = ({ title, value, options }: { title: string; value?: string; options?: any }) => {
    return (
        <Flex width="100%" justifyContent="space-between" mt="16px">
            <Text color="#8C8D8E" fontSize="14px" fontWeight="500">
                {title}
            </Text>
            {options || (
                <Text color="#02111B" fontSize="14px" fontWeight="500">
                    {value}
                </Text>
            )}
        </Flex>
    );
};

const Item = styled.div`
    display: grid;
`;
const TransactionInformation = styled.div`
    margin-top: 16px;
    display: grid;
    gap: 10.625rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
`;
const TextLabel = styled(Text)`
    color: #02111b;
    font-weight: 600;
    font-size: 16px;
`;
const CsUnit = styled.span`
    font-size: 12px;
    @media screen and (max-width: 600px) {
        font-size: 10px;
    }
`