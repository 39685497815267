import { Button, Flex } from "@pancakeswap/uikit";
import GetWindowSize from "@pancakeswap/uikit/src/util/getWindowSize";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import React from "react";
import { GetDataListOrder, UseCoreListOrder } from "state/Order";
import styled from "styled-components";

import { renderMarketplaceAddress } from "utils/renderAddress";

import TabsAsset from "./TabsAsset";
import TabsHistory from "./TabsHistory";
import TabsOrder from "./TabsOrder";

const FooterTabs = () => {
    const [tabActive, setTabActive] = React.useState<number>(0);
    const windowSize = GetWindowSize();
    const { chainId } = useActiveWeb3React();
    const marketplaceAddress = renderMarketplaceAddress(chainId);

    const handleChangeTabs = (index: number) => setTabActive(index);

    return (
        <WrapperFooterContainer>
            <ContainerSubTab>
                <CsButton isActive={tabActive === 0 ? !false : false} onClick={() => handleChangeTabs(0)}>
                    SỔ LỆNH
                </CsButton>
                <CsButton isActive={tabActive === 1 ? !false : false} onClick={() => handleChangeTabs(1)}>
                    TÀI SẢN
                </CsButton>
                <CsButton isActive={tabActive === 2 ? !false : false} onClick={() => handleChangeTabs(2)}>
                    TRA CỨU
                </CsButton>
            </ContainerSubTab>
            <CsFooterContent>
                {tabActive === 0 && <TabsOrder windowSize={windowSize}/>}
                {tabActive === 1 && <TabsAsset windowSize={windowSize} />}
                {tabActive === 2 && <TabsHistory windowSize={windowSize} />}
            </CsFooterContent>
        </WrapperFooterContainer>
    );
};

export default FooterTabs;

const WrapperFooterContainer = styled(Flex)`
    background: ${({ theme }) => theme.colors.white};
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    padding: 0 16px 3rem;
    width: 100%;
    min-height: 100vh;
    justify-content: flex-start;
    @media screen and (max-width: 1000px) {
        min-height: auto;
        margin-top: 1rem;
        padding: 0 16px 3rem;
    }
    @media screen and (max-width: 600px) {
        margin-top: 1rem;
        padding: 0 10px 3rem;
    }
`;

const CsFooterContent = styled(Flex)`
    width: 100%;
    display: flex;
    flex-flow: column;
    max-width: 1280px;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
`;

const ContainerSubTab = styled(Flex)`
    max-width: 1140px;
    grid-template-columns: repeat(3, 1fr);
    width: 100% !important;
    display: grid;
    height: 84px;
    padding: 8px;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 8px;
    align-items: center;
    margin-top: 3.375rem;
    gap: 10px;
    @media screen and (max-width: 600px) {
        height: 64px;
        gap: 5px;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 1.563rem;
    }
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        height: 64px;
        margin-top: 1.563rem;
    }
`;
const CsButton = styled(Button)<{ isActive: boolean }>`
    height: 64px;
    border-radius: 8px;
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : "transparent")};
    color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.primary)};
    font-size: 22px;
    font-weight: 700;
    transition: 0.5s;
    @media screen and (max-width: 600px) {
        font-size: 14px;
        height: 48px;
        padding-left: 0px;
        padding-right: 0px;
    }
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 16px;
        height: 48px;
    }
`;
