/* eslint-disable no-param-reassign */
import { useTranslation } from "@pancakeswap/localization";
import {
    AutoRenewIcon,
    Box,
    Button,
    CheckboxV2,
    Flex,
    HelpIconCcqIcon,
    HelpIconCcqIcon2,
    Input,
    MinusIcon,
    OpenNewIconFundgo,
    PastIcon,
    PlusIcon,
    Skeleton,
    Text
} from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import ConnectWalletButton from "components/ConnectWalletButton";
import { ExchangeToken } from "components/ExchangeToken";
import { LightTooltip2 } from "components/ToolTip2";
import { LightTooltip3 } from "components/ToolTip3";
import { UNITDIFF, UNIT_DATE } from "config";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import { ListAssestItem } from "state/asset/type";
import styled from "styled-components";
import { getBlockExploreLink } from "utils";
import { convertDate, getDateBetweenTwoDates } from "utils/converDate";
import { isAddress } from "web3-utils";
import { GetNftInfo } from "../hook/fetchData";
import { useApproveRedeem } from "../hook/useApproveRedeem";
import { useDownload } from "../hook/useDownload";
import { useListp2p } from "../hook/useListp2p";
import { useSellnow } from "../hook/useSellNow";
import { CsFlexAgree, CsFlexHeader, CsLink, CsModal, CustomLink, WrapItemMobile } from "../styles";

interface ModalProps {
    title: React.ReactNode;
    onDismiss?: () => void;
    windowSize: number;
    account: string;
    chainId: number;
    item: ListAssestItem;
}

const SellModal: React.FC<React.PropsWithChildren<ModalProps>> = ({
    item,
    windowSize,
    title,
    account,
    chainId,
    onDismiss,
    ...props
}) => {
    const { t } = useTranslation();
    function handleOpenLink(address) {
        window.open(getBlockExploreLink(address, "address", chainId));
    }
    function sAccount(dataAddress) {
        if (dataAddress?.length > 0) {
            return `${dataAddress.substring(0, 4)}...${dataAddress.substring(dataAddress.length - 4)}`;
        }
        return "";
    }
    const [sellNow, setSellNow] = useState(true);
    const [amountBuy, setAmountBuy] = useState(1);
    const [addressBuyer, setAddressBuyer] = useState("");
    const [addressBuyerEllipsis, setaddressBuyerEllipsis] = useState<string | null>(null);
    const [fillprice, setFillprie] = useState<string>();

    const checkDisableBtnBuy = !sellNow && (isAddress(addressBuyer) === false || fillprice === '' || Number(amountBuy) > Number(item?.nftAddress))
    // const isAllowance = new BigNumber(dataAsset?.dataUser?.allowance).isLessThan(item?.investmentAmount);

    useEffect(() => {
        setFillprie("");
    }, [sellNow]);

    useEffect(() => {
        if (window.screen.width < 321) {
            const accEllipsis =
                isAddress(addressBuyer) === true && addressBuyer
                    ? `${addressBuyer.substring(0, 2)}...${addressBuyer.substring(addressBuyer.length - 2)}`
                    : null;
            setaddressBuyerEllipsis(accEllipsis);
        }
        if (window.screen.width > 322) {
            const accEllipsis =
                isAddress(addressBuyer) === true && addressBuyer
                    ? `${addressBuyer.substring(0, 4)}...${addressBuyer.substring(addressBuyer.length - 4)}`
                    : null;
            setaddressBuyerEllipsis(accEllipsis);
        }
    }, [addressBuyer]);

    const handleChangeSell = (value: boolean) => {
        setSellNow(value);
    };

    const handlePlus = () => {
        setAmountBuy(amountBuy + 1);
    };

    const handleMinus = () => {
        setAmountBuy(amountBuy - 1);
    };

    const handleChangeInput = (e) => {
        const { value } = e.target;
        if (/^\d+$/.test(value) || value === "") {
            if (Number(value) >= item?.nftBalance) {
                setAmountBuy(item?.nftBalance)
            }
            else {
                setAmountBuy(Number(value));
            }
        }
    };

    const handleChangeAddress = (e) => {
        if (!/^(0x)?[0-9a-f]{40}$/i.test(e)) {
            setAddressBuyer(e.target.value);
        }
    };

    // onPaste
    const pasteHandler = async () => {
        await navigator?.clipboard?.readText()?.then((clipText) => {
            setAddressBuyer(clipText);
        });
    };



    const { handleApproveRedeem, requestedApproveRedeem, pendingApproveRedeem } = useApproveRedeem(item?.nftAddress, account, chainId)
    const { handleListp2p, pendingListp2p } = useListp2p(item?.nftAddress, addressBuyer, item?.buys, amountBuy, fillprice, account, chainId, onDismiss)
    const { handleSellnow, pendingSellNow } = useSellnow(item?.nftAddress, item?.buys, amountBuy, account, chainId, onDismiss)

    const raw = JSON.stringify({
        "buyerWalletAddress": sellNow ? item?.nftAddress : addressBuyer,
        "sellerWalletAddress": account,
        "pendingEtf": [
            {
                "numericalOrder": "1",
                "etfId": `${item?.nftId}`,
                "parValue": `${Number(item?.denominations).toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 })} VND`,
                "interest": `${Number(item?.profit)}%`,
                "incomingInterestDate": convertDate(item?.vestingMap[0]?.nextInterestPaymentDate * 1000),
                "releaseDate": convertDate(Number(item?.issueDate) * 1000),
                "maturityDate": convertDate(Number(item?.expireDate) * 1000),
                "transferValue": `${Number(fillprice).toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 })} VND`
            }
        ],
        "transferedEtf": [
            {
                "numericalOrder": "1",
                "etfId": `${item?.nftId}`,
                "transferDate": convertDate(Date.now())
            }
        ],
        "transferDate": convertDate(Date.now()),
        "totalTransferValue": sellNow ? `${Number(item?.denominations).toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 })} VND` : `${Number(fillprice).toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 })} VND`
    });
    const { penddingDowload, handleDownload } = useDownload(raw)
    const { nftInfo } = GetNftInfo(item?.nftAddress, item?.nftId, account, chainId)
    const holdingTime = moment(Date.now()).diff(moment(nftInfo?.holdingTime), UNITDIFF)

    const currentYield = useMemo(() => {
        if (holdingTime <= 0) {
            return 0
        }
        return new BigNumber(nftInfo?.yieldCurrent).multipliedBy(amountBuy).decimalPlaces(2, 1).toString()
    }, [amountBuy, holdingTime, nftInfo?.yieldCurrent])

    const investmentAmount = useMemo(() => {
        return new BigNumber(item?.nftBalance).multipliedBy(nftInfo?.originalInvestment).toString()
    }, [item, nftInfo])

    const toltalPriceSellNow = useMemo(() => {
        if (holdingTime <= 0) {
            return new BigNumber(new BigNumber(nftInfo?.originalInvestment)).multipliedBy(amountBuy).toString()
        }
        return new BigNumber(new BigNumber(nftInfo?.originalInvestment).plus(nftInfo?.yieldCurrent)).multipliedBy(amountBuy).toString()
    }, [amountBuy, holdingTime, nftInfo?.originalInvestment, nftInfo?.yieldCurrent])

    const totalVested = useMemo(() => {
        return item?.fullVestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [item?.fullVestingMap])
    const totalVestingMap = useMemo(() => {
        return item?.fullVestingMap?.length
    }, [item?.fullVestingMap?.length])

    const marketPrice = (Number(toltalPriceSellNow) / 100) * 20; // => 20%  market price
    const lessMarketPrice = Number(toltalPriceSellNow) - marketPrice;
    const biggerMarketPrice = marketPrice + Number(toltalPriceSellNow);
    const isLessMarketPrice = Number(fillprice) <= (Number(toltalPriceSellNow) - marketPrice);
    const isBiggerMarketPrice = Number(fillprice) >= (marketPrice + Number(toltalPriceSellNow));
    const isSalesConfirmation = isLessMarketPrice || isBiggerMarketPrice;
    const isCheckBuyP2P = item?.issueDate * 1000 < Date.now();
    const ComponentConfirmation = ({ compare }) => {
        return (
            compare && !sellNow && amountBuy !== 0 && Number(fillprice) !== 0 && <>
                {
                    isLessMarketPrice &&
                    <Flex>
                        <Text color="red" fontSize="12px">Tổng tiền thỏa thuận phải lớn hơn 80% giá thị trường
                            ({Number(lessMarketPrice).toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })} VND)</Text>
                    </Flex>
                }
                {isBiggerMarketPrice &&
                    <Flex>
                        <Text color="red" fontSize="12px">Tổng tiền thỏa thuận phải thấp hơn 120% giá thị trường
                            ({Number(biggerMarketPrice).toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })} VND)
                        </Text>
                    </Flex>
                }
            </>
        )
    };

    return (
        <CsModal title={title} onDismiss={onDismiss} {...props}>
            <Flex width="100%" flexDirection="column" alignItems="center">
                <TextTitle>{t("Thông tin Chứng chỉ Quỹ")}</TextTitle>
            </Flex>
            <Box>
                {windowSize > 1000 ? (
                    <>
                        <WrapperItemHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Mệnh giá</Text>
                                <LightTooltip2 title="Mệnh giá (VND)">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <Text width="14.2%">{t("Lợi suất")}</Text>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Kỳ đã nhận</Text>
                                <LightTooltip2 title="Kỳ đã nhận/Tổng kỳ">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>LS hiện tại</Text>
                                <LightTooltip2 title="Lợi suất hiện tại (VND)">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Gốc đầu tư</Text>
                                <LightTooltip2 title="Gốc đầu tư (VND)">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Ngày trả LS</Text>
                                <LightTooltip2 title="Ngày trả lợi suất">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <Text width="14.2%">{t("Ngày còn lại")}</Text>
                        </WrapperItemHeader>

                        <Container>
                            <WrapperCard>
                                <Text width="14.2%">
                                    {new BigNumber(item?.denominations).isNaN() ?
                                        <Skeleton width="42px" />
                                        :
                                        Number(item?.denominations).toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })
                                    }
                                </Text>
                                <Text width="14.2%">{Number(item?.profit)} %</Text>
                                <Text width="14.2%">{totalVested}/{totalVestingMap}</Text>
                                <Text width="14.2%">
                                    {
                                        holdingTime <= 0 ? 0 : Number(new BigNumber(nftInfo?.yieldCurrent).multipliedBy(item?.nftBalance).toString()).toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {new BigNumber(investmentAmount).isNaN() ?
                                        <Skeleton width="42px" />
                                        :
                                        Number(investmentAmount).toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })
                                    }
                                </Text>
                                <Text width="14.2%">{convertDate(item?.vestingMap[0]?.nextInterestPaymentDate * 1000)}</Text>
                                <Text width="14.2%">
                                    {new BigNumber(item?.vestingMap[0]?.nextInterestPaymentDate).isNaN() ?
                                        <Skeleton width="42px" />
                                        :
                                        `${getDateBetweenTwoDates(item?.vestingMap[0]?.nextInterestPaymentDate * 1000)} ${UNIT_DATE}`
                                    }
                                </Text>
                            </WrapperCard>
                        </Container>
                    </>
                ) : (
                    <WrapItemMobile>
                        <Flex width="100%" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                            <CsFlexHeaderMb minWidth="25%" justifyContent="flex-start">
                                <LightTooltip3 title="Mệnh giá">
                                    <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                        <Text ml="4px">
                                            {new BigNumber(item?.denominations).isNaN() ?
                                                <Skeleton width="42px" />
                                                :
                                                Number(item?.denominations).toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                })
                                            }
                                        </Text>
                                        <HelpIconCcqIcon2 />
                                    </Flex>
                                </LightTooltip3>
                            </CsFlexHeaderMb>

                            <CsFlexHeaderMb width="25%" justifyContent="center">
                                <LightTooltip3 title="Kỳ đã nhận/Tổng kỳ">
                                    <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                        <Text>
                                            {totalVested}/{totalVestingMap}
                                        </Text>
                                        <HelpIconCcqIcon2 />
                                    </Flex>
                                </LightTooltip3>
                            </CsFlexHeaderMb>


                            <CsFlexHeaderMb width="25%" justifyContent="flex-end">
                                <LightTooltip3 title="Lợi suất">
                                    <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                        <Text>
                                            {new BigNumber(item?.profit).isNaN() ?
                                                <Skeleton width="42px" />
                                                :
                                                `${item?.profit}%`
                                            }
                                        </Text>
                                        <HelpIconCcqIcon2 />
                                    </Flex>
                                </LightTooltip3>
                            </CsFlexHeaderMb>

                        </Flex>
                        <Flex width="100%" justifyContent="space-between" mt="1rem" alignItems="center" flexWrap="wrap">
                            <CsFlexHeaderMb minWidth="30%" justifyContent="flex-start">
                                <LightTooltip3 title="Ngày còn lại">
                                    <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                        <Text ml="4px">
                                            {new BigNumber(item?.vestingMap[0]?.nextInterestPaymentDate).isNaN() ?
                                                <Skeleton width="42px" />
                                                :
                                                `${getDateBetweenTwoDates(item?.vestingMap[0]?.nextInterestPaymentDate * 1000)} ${UNIT_DATE}`
                                            }
                                        </Text>
                                        <HelpIconCcqIcon2 />
                                    </Flex>
                                </LightTooltip3>
                            </CsFlexHeaderMb>
                            <CsFlexHeaderMb width="33%" justifyContent="center">
                                <LightTooltip3 title="Gốc đầu tư (VND)">
                                    <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                        <Text>
                                            {new BigNumber(investmentAmount).isNaN() ?
                                                <Skeleton width="42px" />
                                                :
                                                Number(investmentAmount).toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                })
                                            }
                                        </Text>
                                        <HelpIconCcqIcon2 />
                                    </Flex>
                                </LightTooltip3>
                            </CsFlexHeaderMb>
                            <CsFlexHeaderMb minWidth="21%" justifyContent="flex-start">
                                <LightTooltip3 title="Ngày trả lợi suất">
                                    <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                        <Text ml="4px">
                                            {new BigNumber(item?.vestingMap[0]?.nextInterestPaymentDate).isNaN() ?
                                                <Skeleton width="42px" />
                                                :
                                                convertDate(item?.vestingMap[0]?.nextInterestPaymentDate * 1000)
                                            }
                                        </Text>
                                        <HelpIconCcqIcon2 />
                                    </Flex>
                                </LightTooltip3>
                            </CsFlexHeaderMb>
                            {/* <Text minWidth="33%" textAlign="right">
                                { new BigNumber(item?.vestingMap[0]?.nextInterestPaymentDate).isNaN() ?
                                    <Skeleton width="42px"/>
                                :
                                    convertDate(item?.vestingMap[0]?.nextInterestPaymentDate * 1000)
                                }
                            </Text> */}
                        </Flex>
                    </WrapItemMobile>
                )}
            </Box>

            <CsFlex>
                {/* Left */}
                <CsBoxLeft>
                    <Flex justifyContent="space-between" width="100%">
                        <Text color="text" fontWeight="700">
                            {t("Thông tin giao dịch")}
                        </Text>
                        <CsFlexMaxAbove
                            width="30%"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <TextLabel>{t("Tối đa")}</TextLabel>
                            {new BigNumber(item?.nftBalance).isNaN() ?
                                <Skeleton width="42px" />
                                :
                                <Text color="primaryBright" bold fontSize="14px">
                                    {item?.nftBalance}
                                </Text>
                            }
                            <Text>CCQ</Text>
                        </CsFlexMaxAbove>
                    </Flex>
                    {/* Chọn số lượng giao dịch */}
                    <Flex justifyContent="space-between" width="100%" mt={["1rem", , "1rem"]} style={{ gap: "10px" }}>
                        <Flex alignItems="center" justifyContent="space-between" width={["100%", , "60%"]}>
                            <TextLabel>{t("Số lượng giao dịch")}</TextLabel>
                            <WrapCount>
                                <ButtonQuanlity disabled={amountBuy <= 1} onClick={handleMinus}>
                                    <MinusIcon />
                                </ButtonQuanlity>

                                <CustomInputQuantity
                                    type="text"
                                    scale="lg"
                                    inputMode="numeric"
                                    placeholder={`${amountBuy}`}
                                    value={amountBuy}
                                    onChange={handleChangeInput}
                                />
                                <ButtonQuanlity
                                    disabled={Number(amountBuy) >= Number(item?.nftBalance)}
                                    onClick={handlePlus}
                                >
                                    <PlusIcon />
                                </ButtonQuanlity>
                            </WrapCount>
                        </Flex>
                        <CsFlexMaxUnder
                            width="30%"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <TextLabel>{t("Tối đa")}</TextLabel>
                            <Flex alignItems="center">
                                {new BigNumber(item?.nftBalance).isNaN() ?
                                    <Skeleton width="42px" />
                                    :
                                    <Text color="primaryBright" bold>
                                        {item?.nftBalance}
                                    </Text>
                                }

                                <Text bold fontSize="14px">&nbsp; CCQ</Text>
                            </Flex>
                        </CsFlexMaxUnder>
                    </Flex>
                    {Number(amountBuy) > Number(item?.nftBalance) &&
                        <Flex mt="10px">
                            <Text color="red" fontSize="12px">Số lượng giao dịch nhiều hơn số lượng đang sở hữu</Text>
                        </Flex>
                    }
                    {/* Chọn phương thức bán */}
                    <Flex justifyContent="space-between" width="100%" mt={["1rem", , "1.5rem"]} flexWrap="wrap">
                        <Flex alignItems="center" justifyContent="space-between" width={["100%", , "40%"]}>
                            <TextLabel>{t("Phương thức bán")}</TextLabel>
                        </Flex>
                        <Flex
                            width={["100%", , "50%"]}
                            mt={["0.5rem", , "0"]}
                            justifyContent="space-between"
                            style={{ gap: "10px" }}
                        >
                            <Flex alignItems="center">
                                <CheckboxV2 onChange={() => handleChangeSell(true)} checked={sellNow === !false} />
                                <TextValue ml={["4px", , "6px"]}>{t("Bán ngay")}</TextValue>
                            </Flex>
                            {isCheckBuyP2P && <Flex alignItems="center">
                                <CheckboxV2 onChange={() => handleChangeSell(false)} checked={sellNow === false} />
                                <TextValue ml={["4px", , "6px"]}>{t("Bán thỏa thuận")}</TextValue>
                            </Flex>}
                        </Flex>
                    </Flex>

                    {/* Chọn phương thức bán */}
                    {sellNow === false && (
                        <>
                            <Flex justifyContent="space-between" width="100%" mt={["1rem", , "1.5rem"]}>
                                <Flex alignItems="center" justifyContent="space-between" width="40%">
                                    <TextLabel>{t("Tổng tiền thỏa thuận")}</TextLabel>
                                </Flex>
                                <CsFlexTotal justifyContent="space-between">
                                    <CsNumericFormat
                                        value={fillprice}
                                        decimalScale={0}
                                        thousandSeparator={!false}
                                        placeholder="100,000"
                                        // eslint-disable-next-line no-return-assign, no-param-reassign
                                        onFocus={(e) => (e.target.placeholder = "")}
                                        onValueChange={(values) => {
                                            const { value } = values;
                                            if (Number(value) >= 0) {
                                                setFillprie(value);
                                            }
                                        }}
                                        maxLength={35}
                                        allowNegative={false}
                                    />
                                    <Text bold fontSize="14px" ml="5px">VND</Text>
                                </CsFlexTotal>
                            </Flex>
                            <ComponentConfirmation compare={windowSize <= 1000} />
                        </>
                    )}

                </CsBoxLeft>

                {/* Right */}
                <CsBox>
                    <Flex width="100%" alignItems="center" justifyContent="space-between" mt={["1rem", , "1rem"]}>
                        <TextLabel>{t("Ngày giao dịch")}</TextLabel>
                        <Flex alignItems="center">
                            <TextValue>{convertDate(new Date().getTime())}</TextValue>
                        </Flex>
                    </Flex>
                    {sellNow ? (
                        <Flex mt={["1rem", , "1rem"]} width="100%" alignItems="center" justifyContent="space-between">
                            <TextLabel>{t("Địa chỉ ví thụ hưởng")}</TextLabel>
                            <LightTooltip2 title={account}>
                                <CustomLink style={{ textAlign: "right" }} onClick={() => handleOpenLink(account)}>
                                    <Text fontWeight="700">{sAccount(account)}</Text>
                                    <OpenNewIconFundgo />
                                </CustomLink>
                            </LightTooltip2>
                        </Flex>
                    ) : (
                        <Flex mt={["1rem", , "1rem"]} width="100%" alignItems="center" justifyContent="space-between">
                            <TextLabel>{t("Địa chỉ ví người mua")}</TextLabel>
                            <Flex flexDirection="column" alignItems="flex-end">
                                <CsFlexAddress justifyContent="flex-end" alignItems="center">
                                    <CsPastIcon role="presentation" onClick={pasteHandler}>
                                        <PastIcon />
                                    </CsPastIcon>
                                    <LightTooltip2 title={addressBuyer || addressBuyer}>
                                        <CustomInput
                                            placeholder=""
                                            type="text"
                                            value={addressBuyerEllipsis || addressBuyer}
                                            onChange={handleChangeAddress}
                                        />
                                    </LightTooltip2>
                                </CsFlexAddress>
                                {addressBuyer !== "" && !isAddress(addressBuyer) && (
                                    <CsTextError mt="6px">Địa chỉ ví sai định dạng</CsTextError>
                                )}
                            </Flex>
                        </Flex>
                    )}

                    <Flex mt={["1rem", , "1rem"]} width="100%" alignItems="center" justifyContent="space-between">
                        <TextLabel>{t("Tổng giá trị giao dịch")}</TextLabel>
                        <Flex flexDirection="column" alignItems="flex-end">
                            <Flex flexDirection="column" alignItems="flex-end">
                                <Flex alignItems="center">
                                    <TextValuePrice>
                                        {sellNow ?
                                            <>
                                                {toltalPriceSellNow?.length > 0 ?
                                                    <>
                                                        {Number(toltalPriceSellNow).toLocaleString("en", {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0
                                                        })}
                                                    </>
                                                    :
                                                    "0"
                                                }
                                            </>
                                            :
                                            <>
                                                {fillprice?.length > 0 ?
                                                    <>
                                                        {Number(fillprice).toLocaleString("en", {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0
                                                        })}
                                                    </>
                                                    :
                                                    "0"
                                                }
                                            </>
                                        }

                                    </TextValuePrice>
                                    <Text fontSize={["12px", , "14px"]} color="text" bold ml="5px">VND</Text>
                                </Flex>
                                <ExchangeToken price={sellNow ? toltalPriceSellNow : fillprice} />
                            </Flex>
                        </Flex>

                    </Flex>
                    <Flex mt={["1rem", , "1rem"]} width="100%" alignItems="center" justifyContent="space-between">
                        <TextLabel>{t("Lợi suất hiện tại")}</TextLabel>
                        <Flex flexDirection="column" alignItems="flex-end">
                            <Flex alignItems="center">
                                <Flex alignItems="center">
                                    {new BigNumber(currentYield).isNaN() ?
                                        <Skeleton width="42px" />
                                        :
                                        <Flex>
                                            <TextValuePrice fontWeight="700" fontSize="14px">
                                                {
                                                    Number(currentYield).toLocaleString("en", {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 0
                                                    })
                                                }

                                            </TextValuePrice>
                                        </Flex>
                                    }
                                </Flex>
                                <Text fontSize={["12px", , "14px"]} color="text" bold ml="5px">VND</Text>
                            </Flex>
                            <Flex mt="4px">
                                <CsUnit style={{ color: "#8C8D8E" }}>
                                    (~{holdingTime <= 0 ? 0 : nftInfo?.rate}%)
                                </CsUnit>
                            </Flex>
                        </Flex>
                    </Flex>
                </CsBox>
            </CsFlex>

            {/* {
                sellNow && (
                    <>
                        <Flex width="300px" justifyContent="space-between">
                                <Text fontSize="12px">Thông tin để test lợi suất</Text>
                                <Text fontSize="12px">Khi deploy sẽ bỏ phần này</Text>
                        </Flex>
                        { windowSize > 1000 &&
                            <Flex width="300px" justifyContent="space-between">
                                <Text fontSize="12px">Thời điểm mua</Text>
                                <Text>{convertDate(nftInfo?.holdingTime)}</Text>
                            </Flex>
                        }
                        { windowSize > 1000 &&
                            <Flex width="300px" justifyContent="space-between">
                                <Text fontSize="12px">Tổng thời gian nắm giữ</Text>
                                <Text>{ holdingTime < 0 ? 0 : holdingTime}</Text>
                            </Flex>
                        }
                        { windowSize > 1000 &&
                            <Flex width="300px" justifyContent="space-between">
                                <Text fontSize="12px">Ngày phát hành</Text>
                                <Text>{convertDate(nftInfo?.issueDate)}</Text>
                            </Flex>
                        }
                    </>
                )
            } */}
            <ComponentConfirmation compare={windowSize >= 1000} />
            {/* start update thêm gía thị trường */}
            {!sellNow &&
                <>
                    <Flex mt={["1rem", , "1rem"]} width={["100%", , , "50%"]} alignItems="center" justifyContent="space-between">
                        <TextLabel>{t("Giá thị trường")}</TextLabel>
                        <Flex alignItems="center">
                            <TextValuePrice>
                                {!sellNow ?
                                    <>
                                        {toltalPriceSellNow?.length > 0 ?
                                            <>
                                                {Number(toltalPriceSellNow).toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                })}
                                            </>
                                            :
                                            "0"
                                        }
                                    </>
                                    :
                                    <>
                                        {fillprice?.length > 0 ?
                                            <>
                                                {Number(fillprice).toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                })}
                                            </>
                                            :
                                            "0"
                                        }
                                    </>
                                }
                            </TextValuePrice>
                            <Text fontSize="14px" color="text" bold ml="5px">VND</Text>
                        </Flex>
                    </Flex>
                </>
            }
            {/* end update thê gía thị trường */}
            <Flex width="100%" justifyContent="center" mt={["1rem", , , "1.5rem"]}>
                {account?.length > 0 ? (
                    <>
                        {item?.isApprovedForAll ? (
                            <>
                                {sellNow ?
                                    <CsButtonConfirm
                                        disabled={pendingSellNow || item?.paused || amountBuy === 0}
                                        onClick={handleSellnow}
                                        endIcon={pendingSellNow ? <AutoRenewIcon spin color="textSubtle" /> : null}
                                    >
                                        Xác nhận bán
                                    </CsButtonConfirm>
                                    :
                                    <div>
                                        {
                                            account?.toLowerCase() === addressBuyer?.toLowerCase() &&
                                            <Flex
                                                style={{
                                                    textAlign: "center",
                                                    color: "red",
                                                    fontSize: "13px",
                                                    marginTop: "10px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                Địa chỉ ví trùng, vui lòng thay đổi!
                                            </Flex>
                                        }
                                        <CsButtonConfirm
                                            disabled={checkDisableBtnBuy || pendingListp2p || item?.paused || amountBuy === 0 || isSalesConfirmation || account?.toLowerCase() === addressBuyer?.toLowerCase()}
                                            onClick={handleListp2p}
                                            endIcon={pendingListp2p ? <AutoRenewIcon spin color="textSubtle" /> : null}
                                        >
                                            Xác nhận bán
                                        </CsButtonConfirm>
                                    </div>
                                }
                            </>
                        ) : (
                            <>
                                {requestedApproveRedeem ?
                                    <>
                                        {sellNow ?
                                            <CsButtonConfirm
                                                disabled={pendingSellNow || item?.paused || amountBuy === 0}
                                                onClick={handleSellnow}
                                                endIcon={pendingSellNow ? <AutoRenewIcon spin color="textSubtle" /> : null}
                                            >
                                                Xác nhận bán
                                            </CsButtonConfirm>
                                            :
                                            <CsButtonConfirm
                                                disabled={checkDisableBtnBuy || pendingListp2p || item?.paused || isSalesConfirmation || amountBuy === 0}
                                                onClick={handleListp2p}
                                                endIcon={pendingListp2p ? <AutoRenewIcon spin color="textSubtle" /> : null}
                                            >
                                                Xác nhận bán
                                            </CsButtonConfirm>
                                        }
                                    </>
                                    :
                                    <CsButtonConfirm
                                        disabled={pendingApproveRedeem || item?.paused}
                                        onClick={handleApproveRedeem}
                                        endIcon={pendingApproveRedeem ? <AutoRenewIcon spin color="textSubtle" /> : null}
                                    >
                                        Chấp thuận
                                    </CsButtonConfirm>
                                }
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <CsConnectWalletButton />
                    </>
                )}
            </Flex>
            {item?.paused &&
                <Flex width="100%" justifyContent="center" mt={["1rem", , , "1rem"]}>
                    <Text fontSize={["12px", , , "14px"]} color="red">{t("CCQ đang tạm khóa, vui lòng quay lại sau!")}</Text>
                </Flex>
            }
            {
                addressBuyer && fillprice &&
                <CsFlexAgree>
                    <Text>{t("Bằng cách “Xác nhận bán” tôi đồng ý với")}</Text>
                    {checkDisableBtnBuy || penddingDowload ?
                        <CsLink>{t("hợp đồng chuyển nhượng Chứng chỉ Quỹ ")}</CsLink>
                        :
                        <CsLink onClick={handleDownload}>{t("hợp đồng chuyển nhượng Chứng chỉ Quỹ ")}</CsLink>
                    }
                </CsFlexAgree>
            }
        </CsModal>
    );
};

const CsConnectWalletButton = styled(ConnectWalletButton)`
    height: 60px;
    font-size: 20px;
    font-weight: 700;
    min-width: 210px;
    transition: 0.5s;
    @media screen and (max-width: 1000px) {
        border-radius: 4px;
        min-width: 165px;
        font-size: 14px;
        height: 40px;
    }
`;

const CsFlexMaxAbove = styled(Flex)`
    display: none;
    @media screen and (max-width: 767px) {
        display: flex;
    }
`;
const CsFlexMaxUnder = styled(Flex)`
    display: flex;
    cursor: pointer;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;
const CsTextError = styled(Text)`
    color: ${({ theme }) => theme.colors.red};
    font-size: 16px;
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
    }
    @media screen and (max-width: 1000px) {
        font-size: 12px;
    }
`;

const CsNumericFormat = styled(NumberFormat)`
    background-color: transparent;
    outline: none;
    &:focus-visible {
        outline: none;
    }
    ::placeholder {
        color: ${({ theme }) => theme.colors.textSubtle};
        opacity: 1;
    }
`;

const CsPastIcon = styled(Flex)`
    cursor: pointer;
    padding-right: 10px;
    &:active {
        transform: translateY(1px);
    }
`;
const TextLabel = styled(Text)`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.textSubtle};
    @media screen and (max-width: 1000px) {
        font-size: 12px;
    }
`;
const TextValue = styled(Text)`
    font-size: 14px;
    white-space: nowrap;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text};
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
    }
    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
`;
const TextValuePrice = styled(Text)`
    font-size: 14px;
    white-space: nowrap;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primaryBright};
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
    }
    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
`;

const TextTitle = styled(Text)`
    font-size: 34px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text};
    @media screen and (max-width: 1000px) {
        font-size: 24px;
    }
`;

const CsBox = styled(Box)`
    width: 40%;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;
const CsBoxLeft = styled(Box)`
    width: 50%;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;
const CsText = styled(Text)`
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
    &:first-child {
        text-align: left;
    }
`;

const CsFlexTotal = styled(Flex)`
    height: 46px;
    width: 335px;
    padding: 0 24px;
    border-radius: 4px;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    @media screen and (max-width: 1000px) {
        width: 220px;
        height: 40px;
    }
    input {
        text-align: left;
        height: 100%;
        width: 100%;
        border: none;
        color: ${({ theme }) => theme.colors.primary};
        font-weight: 600;
        border-radius: 0;
        padding-right: 10px;
    }
`;
const CsFlexAddress = styled(Flex)`
    height: 40px;
    width: 170px;
    padding: 0 16px;
    border-radius: 4px;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    @media screen and (max-width: 1000px) {
        width: 220px;
    }
    input {
        color: ${({ theme }) => theme.colors.primary};
        text-align: right;
        font-weight: 600;
        border-radius: 0;
        &::placeholder {
            font-size: 14px;
        }
    }
`;
const CustomInput = styled(Input)`
    background: none;
    color: ${({ theme }) => theme.colors.white};
    padding: 0;
    width: 100%;
    text-align: center;
    border: none;
    box-shadow: none;
    height: 100%;
    font-size: 16px;
    @media screen and (max-width: 1000px) {
        font-size: 14px;
    }
`;
const CustomInputQuantity = styled(Input)`
    background: none;
    color: ${({ theme }) => theme.colors.white};
    padding: 0;
    width: 100%;
    text-align: center;
    border: none;
    box-shadow: none;
    height: 100%;
    font-size: 14px;
    &::placeholder {
        color: ${({ theme }) => theme.colors.white};
    }
    @media screen and (max-width: 1000px) {
        font-size: 12px;
    }
`;

const ButtonQuanlity = styled(Button)`
    border: 1px solid transparent;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    box-shadow: none;
    padding: 4px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    @media screen and (max-width: 1000px) {
        padding: 8px;
        height: 16px;
        width: 16px;
    }
`;

const WrapCount = styled(Flex)`
    gap: 4px;
    align-items: center;
    background: ${({ theme }) => theme.colors.success3};
    border-radius: 4px;
    width: 115px;
    height: 34px;
    padding: 4px;
    @media screen and (max-width: 1000px) {
        height: 28px;
        gap: 2px;
        width: 95px;
    }
`;
const CsButtonConfirm = styled(Button)`
    height: 60px;
    font-size: 20px;
    font-weight: 700;
    min-width: 210px;
    transition: 0.5s;
    @media screen and (max-width: 1000px) {
        border-radius: 4px;
        min-width: 165px;
        font-size: 14px;
        height: 40px;
    }
`;

const CsFlex = styled(Flex)`
    justify-content: space-between;
    margin-top: 1rem;
    @media screen and (max-width: 1000px) {
        flex-direction: column;
        margin-top: 2rem;
    }
`;

const WrapperCard = styled(Flex)`
    width: 100%;
    height: 48px;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.background};
    &:hover {
        background: #f5f5f58a;
    }
    ${Text} {
        text-align: center;
        font-size: 14px;
    }
`;
const Container = styled.div<{ isHaving?: boolean; background?: string }>`
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const WrapperItemHeader = styled(Flex)`
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    border-radius: 20px;
    margin: 24px 0 12px 0;
    align-items: center;
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.colors.textSubtle};
    }
    @media screen and (max-width: 600px) {
        padding: 2rem 25px;
    }
`;

const CsFlexHeaderMb = styled(Flex)`
    max-width: 100%;
    align-items: center;
    /* justify-content: center; */
`

const CsUnit = styled.span`
    font-size: 12px;
    @media screen and (max-width: 600px) {
        font-size: 10px;
    }
`
export default SellModal;
