
export function renderStatus(status){
    if(status === 1){
        return 'Thành công'
    } if(status === 2){
        return `Đang chờ xác nhận`
    } if(status === 3){
        return `Đã huỷ`
    }
    return 'Đang chờ xác nhận'
}