import { ChainId } from "@pancakeswap/sdk"
import contracts from "config/constants/contracts"
import { bscTokens, bscTestnetTokens, onusMainnetTokens } from "@pancakeswap/tokens"
import { getAddress } from "./addressHelpers"
import { onusMainnet } from "../../packages/wagmi/chains/chains"

export function renderMarketplaceAddress(chainId) {
    return getAddress(contracts?.ccqMarketplace, chainId)
}
export function renderToken(chainId: number) {
    if (chainId === ChainId.BSC_TESTNET) {
        return bscTestnetTokens?.vndc
    }
    return bscTokens.VNDC
}