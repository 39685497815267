import styled from "styled-components";
import { Tooltip, tooltipClasses } from "@mui/material";

export const LightTooltip2 = styled(({ className, ...props }) => (
    <Tooltip placement="top" {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: 'white',
        "&::before": {
            backgroundColor: '#f5f5f5',
          }
      },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f5',
      color: '#8c8d8e',
      boxShadow: 'red',
      fontSize: 14,
      padding: 10,
      fontWeight: 500,
    },
  }));