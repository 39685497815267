import { BigNumber } from 'bignumber.js';
import { Items } from 'state/MarketPlace/type';

export const totalPrice = (listCart, listItemsOnMarketplace: Items[]) => {
    return listCart?.reduce((sum, i) => {
        if (i?.id === null || i?.id === undefined) {
            return 0
        }
        const items = listItemsOnMarketplace?.find((data) => Number(data?.id) === Number(i?.id))
        return (
            new BigNumber(sum).plus(new BigNumber(i.buyAmount).multipliedBy(items?.unitPrice).toString()).toString()
        )

    }, 0)
}

export const totalItems = (listCart) => {
    return listCart?.reduce((sum, i) => {
        if (i?.id === null || i?.id === undefined) {
            return 0
        }
        return (
            new BigNumber(sum).plus(new BigNumber(i.buyAmount).toString()).toString()
        )

    }, 0)
}