import { ArrowLeftIcon, ArrowRIghtIcon, Flex } from "@pancakeswap/uikit";
import React from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

export const CustomPaginate = ({ total, pageSize, setItemOffset }) => {
    const offset = total % pageSize === 0 ? 0 : 1;
    const perPage = Math.floor(total / pageSize);
    const pageCount = perPage + offset;

    const [pageActive, setPageActive] = React.useState(0);

    const handlePageClick = (event) => {
        setPageActive(event.selected);
        setItemOffset(event.selected * pageSize);
    };

    return (
        <CustomFlex width="100%" mt="1rem" justifyContent="center" height="62px">
            <ReactPaginate
                nextLabel={<ArrowRIghtIcon />}
                previousLabel={<ArrowLeftIcon />}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
                forcePage={pageActive}
            />
        </CustomFlex>
    );
};

const CustomFlex = styled(Flex)`
    margin-bottom: 1.5rem;
    .pagination {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        @media screen and (max-width: 600px) {
            width: 100%;
        }
        * {
            list-style-type: none;
        }
    }
    .page-item {
        margin: 0 0.375rem;
        &:first-child,
        &:last-child {
            a {
                &:hover {
                    color: #13486b;
                    border: none !important;
                }
            }
        }
        &:first-child,
        &:last-child {
            a {
                border: none;
                background: none !important;
            }
        }
        &:last-child.disabled,
        &:first-child.disabled {
            cursor: not-allowed;
        }
    }
    .page-link {
        width: 32px;
        height: 32px;
        display: flex;
        background: #fff;
        align-items: center;
        justify-content: center;
        border-radius: 8px !important;
        color: #02111b;
        border: 1px solid #efefef;
        @media screen and (max-width: 768px) {
            padding: 12px;
        }
        &:focus {
            box-shadow: none !important;
        }
        &:hover {
            background: #13486b;
            color: #fff;
            border: 1px solid #13486b !important;
        }
    }
    .page-item.disabled .page-link {
        background: ${({ theme }) => theme.colors.disabled};
        cursor: not-allowed !important;
        opacity: 0.7;
        pointer-events: none;
    }
    .page-item.active .page-link {
        background: #13486b;
        color: #fff;
        border: none;
    }
`;
