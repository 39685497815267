import React from "react";

import { Flex, Text } from "@pancakeswap/uikit";
import styled from "styled-components";

export const CsItem = styled(Flex)`
    width: 12%;
    height: 100%;
    align-items: center;
    color: ${({ theme }) => theme.colors.text} !important;;
    justify-content: center;
    white-space: nowrap;
    text-align: center;
    ${Text}{
        line-height:21px;
        font-size: 14px;
        white-space: nowrap;
    }
`
export const CsItemWarning = styled(Flex)`
    width: 4%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
`

export const CsItemSmall = styled(Flex)`
    width: 9.5%;
    height: 100%;
    align-items: center;
    color: ${({ theme }) => theme.colors.text} !important;
    justify-content: center;
    text-align: center;
    line-height:21px;
    white-space: nowrap;
`