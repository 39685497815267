import { UNITDIFF } from 'config';
import moment from 'moment';
import { zeroPad } from "react-countdown";


export function convertDate(date: any) {
    if (date) {
        const today = new Date(date);
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const hours = String(today.getHours()).padStart(2, '0');
        const minutes = String(today.getMinutes()).padStart(2, '0');
        const yyyy = today.getFullYear();
        return `${dd}/${mm}/${yyyy}`
    }
    return `--/--/--`
}

export const renderCountdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) return (
        <>00 ngày</>
    )
    return (
        <>{zeroPad(days)} ngày</>
    )
}

export const getDateBetweenTwoDates = (endDate: number) => {

    const formatStartDate = moment(moment(endDate).format("DD-MM-YYYY HH:mm:ss"), "DD-MM-YYYY HH:mm:ss")
    const formatStartDateb = moment(moment(Date.now()).format("DD-MM-YYYY HH:mm:ss"), "DD-MM-YYYY HH:mm:ss")
    const addMinutes = formatStartDate.add(1, UNITDIFF)
    const finalDayDiff = addMinutes.diff(formatStartDateb, UNITDIFF) < 0 ? 0 : addMinutes.diff(formatStartDateb, UNITDIFF)
    if (finalDayDiff < 0) return 0
    if (finalDayDiff > 92) return 92
    return finalDayDiff
}