import { useTranslation } from "@pancakeswap/localization";
import { AutoRenewIcon, Box, Flex, HelpIconCcqIcon, HelpIconCcqIcon2, OpenNewIconFundgo, Skeleton, Text } from "@pancakeswap/uikit";
import GetWindowSize from "@pancakeswap/uikit/src/util/getWindowSize";
import BigNumber from "bignumber.js";
import { LightTooltip2 } from "components/ToolTip2";
import { LightTooltip3 } from "components/ToolTip3";
import { UNIT_DATE } from "config";
import React, { useMemo } from "react";
import { ItemWithVestingMap } from "state/Order/type";
import styled from "styled-components";
import { convertDate, getDateBetweenTwoDates } from "utils/converDate";
import { GetNftInfo } from "../hook/fetchData";
import { useDelistp2p } from "../hook/useDelist";
import { ItemTransaction } from "./ItemTransaction";
import {
    CsButtonConfirm,
    CsFlexHeader,
    CsModal,
    CsTextMobile,
    CsTextValue,
    CustomLink,
    WrapItemMobile,
    WrapQuantity,
    WrapperButton,
    WrapperCard
} from "./styles";

interface ModalProps {
    onDismiss?: () => void;
    listOrder?: any;
    sAccount?: (seller) => string;
    handleOpenLink?: (seller) => void;
    title: string,
    item: ItemWithVestingMap,
    account: string,
    chainId: number
}

const CancelModal: React.FC<React.PropsWithChildren<ModalProps>> = ({
    sAccount,
    handleOpenLink,
    onDismiss,
    item,
    title,
    account,
    chainId,
    ...props
}) => {
    const { t } = useTranslation();

    const windowSize = GetWindowSize();
    const { nftInfo, isLoading } = GetNftInfo(item?.nftAddress, account, item?.nftId, account, chainId)
    const { handleDelistp2p, pendingDelistp2p } = useDelistp2p(account, chainId, item?.nftAddress, item?.buys, onDismiss)

    const totalVested = useMemo(() => {
        return item?.vestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [item?.vestingMap])
    const totalVestingMap = useMemo(() => {
        return item?.vestingMap?.length
    }, [item?.vestingMap?.length])


    return (
        <CsModal title={title} onDismiss={onDismiss} {...props}>
            <Flex width="100%" flexDirection="column" alignItems="center" mt="1rem">
                <CsTitle>{t("Xác nhận huỷ")}</CsTitle>
            </Flex>
            <Box>
                {windowSize > 768 ? (
                    <>
                        <WrapperItemHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Mệnh giá</Text>
                                <LightTooltip2 title="Mệnh giá (VND)">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <Text width="14.2%">Lợi suất</Text>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Kỳ đã nhận</Text>
                                <LightTooltip2 title="Kỳ đã nhận/Tổng kỳ">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Kỳ trả LS</Text>
                                <LightTooltip2 title="Kỳ trả lợi suất">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Ngày trả LS</Text>
                                <LightTooltip2 title="Ngày trả lợi suất">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <Text width="14.2%">Ngày còn lại</Text>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Giá trị</Text>
                                <LightTooltip2 title="Giá trị (VND)">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                        </WrapperItemHeader>
                        <Container>
                            <WrapperCard>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        Number(nftInfo?.price).toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        `${Number(nftInfo?.profit).toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })}%`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        `${totalVested}/${totalVestingMap}`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        `${nftInfo?.intervestTerm} tháng`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {new BigNumber(nftInfo?.nextInterestPaymentDate * 1000).isNaN() ?
                                        `0`
                                        :
                                        `${convertDate(nftInfo?.nextInterestPaymentDate * 1000)}`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {new BigNumber(nftInfo?.nextInterestPaymentDate * 1000).isNaN() ?
                                        `0 ${UNIT_DATE}`
                                        :
                                        `${getDateBetweenTwoDates(nftInfo?.nextInterestPaymentDate * 1000)} ${UNIT_DATE}`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        <>
                                            {new BigNumber(item?.price).isNaN() ?
                                                `0`
                                                :
                                                Number(item?.price).toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                })
                                            }
                                        </>
                                    }
                                </Text>
                            </WrapperCard>
                        </Container>
                        <ItemTransaction
                            titleOrderQuantity="Số lượng giao dịch"
                            valueOrderQuantity={item?.amountTrading?.toString()}
                            valueTransactionMethod="Bán thỏa thuận"
                            dateTransaction={convertDate(item?.dateTrading * 1000)}
                            addressBuy={item?.addressTrading}
                            totalMoney={Number(item?.price)}
                            textAlert="Địa chỉ ví người mua"
                            utiOrderQuantity="CCQ"
                        />
                    </>
                ) : (
                    <>
                        <WrapItemMobile>
                            <WrapQuantity>
                                <Flex
                                    justifyContent="space-between"
                                    alignItems="center"
                                    width="100%"
                                >
                                    <CsFlexHeaderMb width="23.3%">
                                        <LightTooltip3 title="Mệnh giá">
                                            <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                                <Text ml="4px">
                                                    {isLoading ?
                                                        <Skeleton width="42px" />
                                                        :
                                                        <Text bold>
                                                            {
                                                                Number(nftInfo?.price).toLocaleString("en", {
                                                                    minimumFractionDigits: 0,
                                                                    maximumFractionDigits: 0
                                                                })
                                                            }
                                                        </Text>
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </LightTooltip3>
                                    </CsFlexHeaderMb>

                                    <CsFlexHeaderMb width="23.3%" justifyContent="center">
                                        <LightTooltip3 title="Lợi suất">
                                            <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                                <Text ml="4px">
                                                    {isLoading ?
                                                        <Skeleton width="42px" />
                                                        :
                                                        `${nftInfo?.profit}%`
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </LightTooltip3>
                                    </CsFlexHeaderMb>

                                    <CsFlexHeaderMb width="23.3%" justifyContent="flex-end">
                                        <LightTooltip3 title="Kỳ đã nhận/Tổng kỳ">
                                            <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                                <Text ml="4px">
                                                    {totalVested}/{totalVestingMap}
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </LightTooltip3>
                                    </CsFlexHeaderMb>

                                    {/* <CsFlexHeaderMb width="23.3%" justifyContent="center">
                                        <LightTooltip3 title="Giá trị (VNDC)">
                                            <Flex style={{cursor: 'pointer', gap: '2px'}}>
                                                <Text ml="4px">
                                                { isLoading ?
                                                    <Skeleton width="42px"/>
                                                    :
                                                        <>
                                                            { new BigNumber(item?.price).isNaN() ?
                                                                `0`
                                                            :
                                                                Number(item?.price).toLocaleString("en", {
                                                                    minimumFractionDigits: 0,
                                                                    maximumFractionDigits: 0
                                                                })
                                                            }
                                                        </>
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </LightTooltip3>
                                    </CsFlexHeaderMb> */}
                                </Flex>
                                <Flex
                                    mt="8px"
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(3, minmax(0, 1fr))"
                                    }}
                                >

                                    <CsFlexHeaderMb width="100%" justifyContent="flex-start">
                                        <LightTooltip3 title="Kỳ trả lợi suất">
                                            <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                                <Text ml="4px">
                                                    {isLoading ?
                                                        <Skeleton width="42px" />
                                                        :
                                                        <Flex style={{ gap: '4px' }} justifyContent="center">
                                                            <Text style={{ color: '#02111b', fontWeight: '700' }}>{nftInfo?.intervestTerm}</Text>
                                                            <Text>tháng</Text>
                                                        </Flex>
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </LightTooltip3>
                                    </CsFlexHeaderMb>

                                    <CsFlexHeaderMb width="100%" justifyContent="center">
                                        <LightTooltip3 title="Ngày còn lại">
                                            <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                                <Text ml="4px">
                                                    {new BigNumber(nftInfo?.nextInterestPaymentDate * 1000).isNaN() ?
                                                        `0`
                                                        :
                                                        <Text color='#02111b' style={{ fontWeight: "700" }}>
                                                            {
                                                                getDateBetweenTwoDates(nftInfo?.nextInterestPaymentDate * 1000)
                                                            }
                                                            {' '}{UNIT_DATE}
                                                        </Text>
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </LightTooltip3>
                                    </CsFlexHeaderMb>

                                    <CsFlexHeaderMb width="100%" justifyContent="flex-end">
                                        <LightTooltip3 title="Ngày trả lợi suất">
                                            <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                                <Text ml="4px">
                                                    {new BigNumber(nftInfo?.nextInterestPaymentDate * 1000).isNaN() ?
                                                        `0`
                                                        :
                                                        convertDate(nftInfo?.nextInterestPaymentDate * 1000)
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </LightTooltip3>
                                    </CsFlexHeaderMb>
                                </Flex>
                            </WrapQuantity>
                            <Flex width="100%" height="auto" flexDirection="column" style={{ gap: "10px" }}>
                                <Text style={{ color: "#02111b", fontWeight: 700, fontSize: "0.875rem" }}>
                                    Thông tin chứng chỉ quỹ
                                </Text>
                                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                                    <CsTextMobile color="textSubtle">Ngày giao dịch</CsTextMobile>
                                    <CsTextMobile bold>{convertDate(item?.dateTrading * 1000)}</CsTextMobile>
                                </Flex>
                                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                                    <CsTextMobile color="textSubtle">Địa chỉ ví giao dịch thỏa thuận</CsTextMobile>
                                    <CsTextMobile bold>
                                        <CustomLink
                                            style={{ textAlign: "right" }}
                                            onClick={() => handleOpenLink(item?.addressTrading)}
                                        >
                                            <Text style={{ fontWeight: "600", color: '#02111b' }}>
                                                {sAccount(item?.addressTrading)}
                                            </Text>
                                            <OpenNewIconFundgo />
                                        </CustomLink>
                                    </CsTextMobile>
                                </Flex>
                                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                                    <CsTextMobile color="textSubtle">Số lượng</CsTextMobile>
                                    <CsTextMobile bold>{item?.amountTrading}</CsTextMobile>
                                </Flex>
                                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                                    <CsTextMobile color="textSubtle">Phương thức thanh toán</CsTextMobile>
                                    <CsTextMobile fontSize="12px" bold>Bán thỏa thuận</CsTextMobile>
                                </Flex>
                                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                                    <CsTextMobile color="textSubtle">Tổng tiền thỏa thuận</CsTextMobile>

                                    <Flex flexDirection="column" alignItems="flex-end">
                                        <Flex flexDirection="row">
                                            <CsTextValue>
                                                {Number(item?.price).toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                })}
                                            </CsTextValue>
                                            <Text bold color="text" ml="5px">VND</Text>
                                        </Flex>
                                        {Number(item?.price) !== 0 &&
                                            <CsUnit style={{ color: "#8C8D8E" }}>
                                                {` (Tương đương ${Number(item?.price).toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                })} điểm VNDC)`}
                                            </CsUnit>
                                        }
                                    </Flex>

                                </Flex>
                            </Flex>
                        </WrapItemMobile>
                    </>
                )}
            </Box>
            <WrapperButton>
                {
                    !item.isBuyP2P &&
                    <Flex
                        style={{
                            color: "red",
                            fontSize: "14px",
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "10px",
                        }}
                    >Đã hết kỳ nhận lợi suất của CCQ không thể thực hiện giao dịch, vui lòng xác nhận hủy!</Flex>
                }
                <Flex style={{ gap: "10px" }} width="100%" justifyContent="center">
                    <CsButtonConfirm
                        disabled={pendingDelistp2p || item?.paused}
                        onClick={handleDelistp2p}
                        endIcon={pendingDelistp2p ? <AutoRenewIcon spin color="textSubtle" /> : ""}
                    >
                        {t("Xác nhận hủy")}
                    </CsButtonConfirm>
                </Flex>
            </WrapperButton>
            {item?.paused === true &&
                <Flex width="100%" justifyContent="center" mt={["1rem", , , "1rem"]}>
                    <Text fontSize={["12px", , , "14px"]} color="red">{t("CCQ đang tạm khóa, vui lòng quay lại sau!")}</Text>
                </Flex>
            }
        </CsModal>
    );
};
const CsImgCoin = styled.img`
    width: 22px;
    height: 22px;
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
    }
`;
const Container = styled.div<{ isHaving?: boolean; background?: string }>`
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const WrapperItemHeader = styled(Flex)`
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border-radius: 20px;
    margin: 24px 0 24px 0;
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.colors.textSubtle};
    }
    @media screen and (max-width: 600px) {
        padding: 2rem 25px;
    }
`;

const CsTitle = styled(Text)`
    color: ${({ theme }) => theme.colors.text};
    font-size: 28px;
    font-weight: 700;
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
`;
const CsFlexHeaderMb = styled(Flex)`
    max-width: 100%;
    align-items: center;
    /* justify-content: center; */
`
const CsUnit = styled.span`
    font-size: 12px;
    margin-top: 4px;
    @media screen and (max-width: 600px) {
        font-size: 10px;
    }
`

export default CancelModal;