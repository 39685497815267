import { useTranslation } from "@pancakeswap/localization";
import { Flex, ListIcon, Text } from "@pancakeswap/uikit";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { fetchCartItems } from "state/MarketPlace/actions";
import { CartItem, Items } from "state/MarketPlace/type";
import styled from "styled-components";

import { CustomPaginate } from "../components";
import ItemMobile from "./ItemMobile";
import Item from "./ItemPC";

interface PropsMarketplace {
    listItem: Items[];
    windowSize: number;
    listCart: CartItem[];
}

const pageSize = 7;

const Ccq: React.FC<PropsMarketplace> = ({ listItem, windowSize, listCart }) => {

    const { t } = useTranslation();
    const [itemOffset, setItemOffset] = useState(0);
    const dispatch = useDispatch<AppDispatch>();

    const handleChoose = (id, addresses, buys) => {
        dispatch(fetchCartItems({ listCart: [...listCart, { buyAmount: 1, id, addresses, buys }] }));
    };

    const filterData = listItem.filter((item) => !listCart.find((data) => Number(data.id === Number(item.id))));

    const total = filterData.length;
    return (
        <>
            {windowSize > 1000 ? (
                <AnotherCCQ>
                    {listCart?.length > 0 &&
                        <SeeAnother>Xem thêm các Chứng chỉ Quỹ khác</SeeAnother>
                    }
                    {listItem?.length !== 0 && (
                        <CsFlex>
                            {filterData.splice(itemOffset, pageSize).map((item) => (
                                <Item {...item} handleChoose={handleChoose} />
                            ))}
                        </CsFlex>
                    )}
                </AnotherCCQ>
            ) : (
                <AnotherCCQ>
                    {listItem?.length !== 0 && (
                        <>
                            <FlexAnother>
                                <Flex alignItems="center">
                                    <ListIcon />
                                    {listCart?.length > 0 &&
                                        <TextAnother>{t("Xem thêm các Chứng chỉ Quỹ khác")}</TextAnother>
                                    }
                                </Flex>
                                <Flex alignItems="center">
                                    <TextAddToCart>{t("Thêm vào giỏ")}</TextAddToCart>
                                </Flex>
                            </FlexAnother>
                            <CsFlexMobile>
                                {filterData.splice(itemOffset, pageSize).map((item) => (
                                    <ItemMobile {...item} handleChoose={handleChoose} />
                                ))}
                            </CsFlexMobile>
                        </>
                    )}
                </AnotherCCQ>
            )}
            {listItem?.length > 7 && <CustomPaginate total={total} pageSize={pageSize} setItemOffset={setItemOffset} />}
        </>
    );
};

export default Ccq;

const SeeAnother = styled(Text)`
    font-size: 18px;
    margin: 1rem 0;
    color: ${({ theme }) => theme.colors.textDisabled};
`;

const FlexAnother = styled(Flex)`
    width: 100%;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    background: #efefef;
    justify-content: space-between;
`;

const TextAnother = styled(Text)`
    color: ${({ theme }) => theme.colors.text};
    margin-left: 14px;
    font-size: 12px;
`;
const TextAddToCart = styled(Text)`
    color: ${({ theme }) => theme.colors.textDisabled};
    margin-right: 6px;
    font-size: 10px;
`;

const AnotherCCQ = styled.div`
`;

const CsFlex = styled(Flex)`
    flex-direction: column;
`;

const CsFlexMobile = styled(Flex)`
    flex-direction: column;
`;
