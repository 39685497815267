import { useTranslation } from '@pancakeswap/localization';
import { Button, Flex, Text } from '@pancakeswap/uikit';
import { MINIMUM } from 'config/constants';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'state';
import { fetchCartItems } from 'state/MarketPlace/actions';
import { CartItem, Items, UserItems } from 'state/MarketPlace/type';
import styled from 'styled-components';

interface PropHeader {
    listItem: Items[],
    dataUser: UserItems,
    account: string,
}

const SubHeader: React.FC<PropHeader> = ({
    listItem,
    dataUser,
    account,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const [fillPrice, setFillPice] = useState<string>();

    const sortListItem = [...listItem]?.sort((a, b) => b?.unitPrice - a?.unitPrice);

    const addThousandPrice = sortListItem.map((item) => {
        const buysThousandPrice = item.buys.map((b) => ({
            ...b,
            amount: b.amount * item.unitPrice
        }));
        return {
            buys: buysThousandPrice,
            id: item.id,
            addresses: item.nftAddress,
            unitPrice: item.unitPrice
        }
    });


    const handleSearch = () => {
        const resultFilter: CartItem[] = [];
        let amountBuy = Number(fillPrice);
        addThousandPrice.forEach(obj => {
            const buys = obj.buys.map((b) => ({
                ...b,
                amount: b.amount / obj.unitPrice,
            }));
            obj.buys.forEach(item => {
                if (item.amount > 0 && amountBuy > 0) {
                    const quantityToBuy = Math.min(amountBuy, item.amount);
                    resultFilter.push({
                        addresses: obj.addresses,
                        buys,
                        id: item.id,
                        buyAmount: quantityToBuy / obj.unitPrice,
                    });
                    const updatedAmount = item.amount - quantityToBuy;
                    const updatedBuyAmount = amountBuy - quantityToBuy;
                    // eslint-disable-next-line no-param-reassign
                    obj.buys = obj.buys.map(buy =>
                        buy.id === item.id ? { ...buy, amount: updatedAmount } : buy
                    );
                    // eslint-disable-next-line no-param-reassign
                    amountBuy = updatedBuyAmount;
                }
            });
        });
        const result = resultFilter.reduce((acc: CartItem[], pre: CartItem) => {
            const index = acc.findIndex(item => item?.addresses?.toLowerCase() === pre?.addresses?.toLowerCase());
            if (index === -1) {
                acc.push({
                    ...pre,
                    buyAmount: Math.floor(pre.buyAmount)
                })
            }
            else {
                const { buyAmount } = acc[index];
                // eslint-disable-next-line no-param-reassign
                acc[index] = {
                    ...acc[index],
                    buyAmount: Math.floor(buyAmount + pre.buyAmount)
                }
            }
            return acc;
        }, []);
        dispatch(fetchCartItems({ listCart: result }))
    }
    return (
        <SearchHeading>
            <CsFirstHeading>{t('Tôi muốn mua')}</CsFirstHeading>
            <CsSecondHeading>
                <CsNumericFormat
                    value={fillPrice}
                    decimalScale={2}
                    thousandSeparator={!false}
                    placeholder="1,000,000"
                    // eslint-disable-next-line no-return-assign, no-param-reassign
                    onFocus={(e) => e.target.placeholder = ""}
                    onValueChange={(values) => {
                        const { value } = values;
                        if (Number(value) >= 0) {
                            setFillPice(value)
                        }
                    }}
                    isAllowed={(values) => {
                        const { value } = values;
                        return account ? Number(value) <= (Number(dataUser?.balance)) : Number(value) >= 0;
                    }}
                    maxLength={35}
                    allowNegative={false}
                />
                <Text color='textSubtle'>{t('Chứng chỉ Quỹ (VND)')}</Text>
            </CsSecondHeading>
            <CsThirstHeading>
                <Text>{t('Số dư tài khoản')}</Text>
                <Text style={{ color: "#F9A138", fontWeight: 500 }}>{account ? `${Number(dataUser?.balance).toLocaleString('en', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })}` : '0'}
                    <span style={{ fontWeight: 600, marginLeft: "6px" }}>VND</span>
                </Text>
            </CsThirstHeading>
            <CsThirstHeading>
                <Text>{t('Mệnh giá Chứng chỉ Quỹ')}</Text>
                <Text style={{ color: "#F9A138", fontWeight: 500 }}>{`${(100000).toLocaleString('en', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })}`} <span style={{ fontWeight: 600, marginLeft: "0px" }}>VND</span></Text>
            </CsThirstHeading>
            <CsThirstHeading>
                <Text>{t('Số tiền giao dịch tối thiểu')}</Text>
                <Text style={{ color: "#F9A138", fontWeight: 500 }}>
                    {(1000000).toLocaleString('en', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                    <span style={{ fontWeight: 600, marginLeft: "0px" }}> VND</span>
                </Text>
            </CsThirstHeading>
            <WrapButton>
                <CsButtonSearch onClick={handleSearch} disabled={Number(fillPrice) < MINIMUM || !fillPrice}>Tìm mua</CsButtonSearch>
            </WrapButton>
        </SearchHeading>
    )
}

export default SubHeader

const CsNumericFormat = styled(NumberFormat)`
    background-color: transparent;
    outline: none;
    &:focus-visible {
        outline: none;
    }
    ::placeholder {
        color:${({ theme }) => theme.colors.text};
        opacity: 1;
    }
`

const WrapButton = styled(Flex)`
    width: 100%;
    justify-content: center;
    margin-top: 34px;
    @media screen and (max-width: 768px){
        margin-top: 17px;
    }
    @media screen and (max-width: 600px){
        margin-top: 10px;
    }
`
const CsButtonSearch = styled(Button)`
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    height: 75px;
    border-radius: 12px;
    @media screen and (max-width: 768px){
        border-radius: 4px;
        font-size: 14px;
        height: 50px;
        padding: 0 16px;
    }
    @media screen and (max-width: 600px){
        padding: 0 16px;
        font-size: 12px;
        line-height: 14px;
        height: 40px;
    }
`

// start search Heading
const SearchHeading = styled.div`
    text-align: center;
    width: 100%;
    max-width: 620px;
    @media screen and (max-width: 768px){
        padding: 0 36px;
    }
`
const CsFirstHeading = styled(Text)`
    margin-top: 34px;
    letter-spacing: 0.04em;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    color: ${({ theme }) => theme.colors.primary};
    @media screen and (max-width: 600px){
        font-size: 24px;
    }
`
const CsSecondHeading = styled(Flex)`
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 12px 36px;
    margin: 34px auto 0;
    border-radius: 4px;
    @media screen and (max-width: 768px){
        margin: 16px 0;
    }
    @media screen and (max-width: 600px){
        padding: 18px 16px;
    }
    input{
        padding-right: 16px;
        width: 75%;
        border: none;
        color: ${({ theme }) => theme.colors.primary};
        font-weight: 700;
        font-size: 32px;
        line-height: 49px;
        letter-spacing: 0.04em;
        &::placeholder{
            color: ${({ theme }) => theme.colors.textDisabled};
        }
        @media screen and (max-width: 768px){
            font-size: 20px;
            line-height: 49px;
        }
        @media screen and (max-width: 600px){
            font-size: 14px;
            line-height: 16px;
        }
    }
    ${Text}{
        white-space: nowrap;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        @media screen and (max-width: 600px){
            font-size: 10px;
            line-height: 12px;
        }
    }
`
const CsThirstHeading = styled(Flex)`
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    margin-top: 34px;
    @media screen and (max-width: 768px){
        margin-top: 17px;
        max-width: 100%;
    }
    @media screen and (max-width: 600px){
        margin-top: 6px;
    }
    ${Text}{
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: ${({ theme }) => theme.colors.textSubtle};
        @media screen and (max-width: 768px){
            font-size: 18px;
            line-height: 1.5;
        }
        @media screen and (max-width: 600px){
            font-size: 12px;
            line-height: 1.5;
        }
    }
    span{
        color: ${({ theme }) => theme.colors.text};
        margin-left: 4px;
    }
`