import { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import useTheme from 'hooks/useTheme'
import { ArrowDropDownIcon, Box, BoxProps, Flex, IconSort, Text } from '@pancakeswap/uikit'

const DropDownHeader = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  justify-content: space-between;
  transition: border-radius 0.15s;
  color: #fdb533;

  @media screen and (max-width: 1024px) {
    padding: 0 10px;
    justify-content: center;
  }
`

const DropDownListContainer = styled.div`
  width: 180px;
  height: 100%;
  padding: 12px;
  max-height:250px;
  position: absolute;
  overflow-y: hidden;
  overflow-x:hidden;
  z-index: ${({ theme }) => theme.zIndices.dropdown};
  transition: transform 0.15s, opacity 0.15s;
  transform: scaleY(0);
  transform-origin: top;
  opacity: 0;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.tooltip.boxShadow};
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => (theme.isDark ? '#fdb533' : '#fff')} !important;
  ${({ theme }) => theme.mediaQueries.sm} {
    min-width: 50px;
    width: 160px;
  }
`

const DropDownContainer = styled.div<{ isOpen: boolean; width: number; height: number }>`
  width: ${({ width }) => width}px;
  position: relative;
  cursor: pointer;
  min-width: 180px;
  max-width: 220px;
  height: 48px;
  display: flex;
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    height: 40px;
    min-width: 120px;
    border-radius:4px;
  }
  @media only screen and (max-width: 600px) {
    max-width: 160px;
  }
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 12px;
  ${(props) =>
    props.isOpen &&
    css`
      ${DropDownHeader} {
        /* box-shadow: ${({ theme }) => theme.tooltip.boxShadow}; */
      }
      ${DropDownListContainer} {
        opacity: 1;
        height: auto;
        transform: scaleY(1);
        border-top-width: 0;
        background:  ${({ theme }) => theme.colors.white};
        border-radius: 12px;
        width: 100%;
        transform: translateY(3.063rem);
        @media only screen and (max-width: 1024px){
          border-radius: 4px;
        }
      }
    `}

  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    fill:  ${({ theme }) => theme.colors.primary} !important;
    @media only screen and (max-width: 1024px) {
      right: 9px;
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    /* margin-top:1rem; */
  }
`

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background:  ${({ theme }) => theme.colors.white};
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`

const ListItem = styled.li`
  list-style: none;
  padding: 8px;
  border-radius:4px;
  &:hover {
    background: ${({ theme }) => theme.colors.primaryBright};
    > div {
      color: #fff !important;
    }
  }
`

const CsInconSort = styled(Flex)`
    width: 58px;
    justify-content: space-between;
    border-top-right-radius: 11px;
    border-bottom-right-radius: 11px;
    border-left: 1px solid ${({ theme }) => theme.colors.primary};
    transition: 0.5s;
    &:hover{
      border-left: 1px solid transparent;
      background: ${({ theme }) => theme.colors.primary};
      svg{
        fill: ${({ theme }) => theme.colors.white} !important;
      }
    }
    @media only screen and (max-width: 1024px) {
      width: 40px;      
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
`
export interface SelectProps extends BoxProps {
  options: OptionProps[]
  onOptionChange?: (option: OptionProps) => void
  placeHolderText?: string
  defaultOptionIndex?: number
}

export interface OptionProps {
  label: string
  value: any
}

const Select: React.FunctionComponent<React.PropsWithChildren<SelectProps>> = ({
  options,
  onOptionChange,
  defaultOptionIndex,
  placeHolderText,
  ...props
}) => {
  const containerRef = useRef(null)
  const dropdownRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [optionSelected, setOptionSelected] = useState(false)
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(defaultOptionIndex)
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 })

  const { theme } = useTheme()

  const toggling = (event: React.MouseEvent<HTMLDivElement>) => {
    if ( options.length > 1 ){
      setIsOpen(!isOpen)
    }
    event.stopPropagation()
  }

  const onOptionClicked = (selectedIndex: number) => () => {
    setSelectedOptionIndex(selectedIndex)
    setIsOpen(false)
    setOptionSelected(true)

    if (onOptionChange) {
      onOptionChange(options[selectedIndex])
    }
  }

  useEffect(() => {
    setContainerSize({
      width: dropdownRef.current.offsetWidth, // Consider border
      height: dropdownRef.current.offsetHeight,
    })

    const handleClickOutside = () => {
      setIsOpen(false)
    }

    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (defaultOptionIndex) {
      setSelectedOptionIndex(defaultOptionIndex)
      setOptionSelected(true)
    }
  }, [defaultOptionIndex])

  return (
    <DropDownContainer isOpen={isOpen} {...props} ref={containerRef} {...containerSize}>
      {containerSize.width !== 0 && (
        <DropDownHeader onClick={toggling}>
          <Text color='primary' fontSize='14px'>
            {!optionSelected && placeHolderText ? placeHolderText : options[selectedOptionIndex].label}
          </Text>
        </DropDownHeader>
      )}
      <CsInconSort onClick={toggling} >
        <IconSort color={theme.isDark ? '#fff' : 'primary'}/>
      </CsInconSort>

      <DropDownListContainer>
        <DropDownList ref={dropdownRef}>
          {options.map((option, index) =>
            placeHolderText || index !== selectedOptionIndex ? (
              <CustomListItem onClick={onOptionClicked(index)} key={option.label}>
                <Text>{option.label}</Text>
              </CustomListItem>
            ) : null,
          )}
        </DropDownList>
      </DropDownListContainer>
    </DropDownContainer>
  )
}

export default Select

const CustomListItem = styled(ListItem)`
  @media screen and (max-width: 320px) {
    padding: 0px
  }
  ${Text}{
    color: ${({ theme }) => theme.colors.primary};
    font-size: 14px;
  }
`