import BigNumber from "bignumber.js";
import ccqWrappedAbi from "config/abi/ccqWrappedAbi.json";
import useRefresh from "hooks/useRefresh";
import { useSlowRefreshEffect } from "hooks/useRefreshEffect";
import { useEffect, useState } from "react";
import multicall from "utils/multicall";

export interface NftInfo {
    originalInvestment:string,
    yieldCurrent:string,
 
}
export const GetNftInfo = (nftAddress:string, nftId:number, account:string, chainId: number) => {
    const [ nftInfo, setNftInfo ] = useState({
        originalInvestment:"0",
        yieldCurrent:"0",
        rate:"0",
        holdingTime:0,
        issueDate:0
    })

    const [ isLoading, setLoading ] = useState(false)
    
    useSlowRefreshEffect(() => {
        const getDataNft = async () => {
            try {
                setLoading(true)
                const calls = [
                    {
                        address: nftAddress,
                        name: 'getPriceWhenSellNow',
                        params: [account, nftId]
                    },
                    {
                        address: nftAddress,
                        name: 'getHoldingDate',
                        params: [account, nftId]
                    },
                    {
                        address: nftAddress,
                        name: 'etfInfor',
                        params: []
                    }
                ]
                const [resultEtfInfo, resultHoldingTime, resultIssueDate ] = await multicall(ccqWrappedAbi, calls, chainId); 
                setNftInfo(
                    { 
                        originalInvestment: new BigNumber(resultEtfInfo[0]?.price?.toString()).dividedBy(1E18).toString(),
                        yieldCurrent: new BigNumber(resultEtfInfo[0]?.profit?.toString()).dividedBy(1E18).toString(),
                        rate: new BigNumber(resultEtfInfo[0]?.rate?.toString()).dividedBy(10).toString(),
                        holdingTime:  Number( new BigNumber(resultHoldingTime[0]?.toString()).toString())*1000,
                        issueDate: Number( new BigNumber(resultIssueDate?.issueDate.toString()).toString())*1000,
                    }
                )
                setLoading(false)
            }
            catch(error) {              
                setLoading(false)
            }
        }
        if (nftAddress?.length > 0) {
            getDataNft()
        }
    }, [nftId, chainId, account, nftAddress])
    return { nftInfo, isLoading }
}

export const GetPriceAtTime = (nftAddress:string, holdingTime:number, chainId:number) => {
    const [ priceAtTime, setPriceAtTime ] = useState({
        priceAtTime:"0",
    })
    const [ isLoading, setLoading ] = useState(false)
    useSlowRefreshEffect(() => {
        const getPriceAtTime = async () => {
            try {
                setLoading(true)
                const calls = [
                    {
                        address: nftAddress,
                        name: 'getPriceAtTime',
                        params: [holdingTime]
                    }
                ]
                const [result] = await multicall(ccqWrappedAbi, calls, chainId); 
                setPriceAtTime(
                    { 
                        priceAtTime: new BigNumber(result[0]?.toString()).dividedBy(1E18).toString(),    
                    }
                )
                setLoading(false)
            }
            catch(error) {              
                setLoading(false)
            }
        }
        if (nftAddress?.length > 0) {
            getPriceAtTime()
        }
    }, [chainId, nftAddress, holdingTime])
    return { priceAtTime, isLoading }
}
