import { Flex, Text } from "@pancakeswap/uikit";

import { UNIT_DATE } from "config";
import React, { useMemo } from "react";
import { Items } from "state/MarketPlace/type";
import styled from "styled-components";

interface RowProps {
    buyAmount: number;
    itemSell: Items;
}

const RowForDesktop: React.FC<React.PropsWithChildren<RowProps>> = ({ buyAmount, itemSell }) => {

    return (
        <Container>
            <WrapperCard>
                <Text width="14%">{buyAmount}</Text>
                <Text width="14%">{`${itemSell.interVestTerm} tháng`}</Text>
                <Text width="14%">
                    {Number(itemSell.price).toLocaleString("en", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    })}
                </Text>
                <Text width="14%">{itemSell.interVestTermRate}%</Text>
                <Text width="14%">{itemSell.paydayNextProfit}</Text>
                <Text width="14%">{`${itemSell.dayDiff} ${UNIT_DATE}`}</Text>
                <Text width="14%">
                    {Number(itemSell?.unitPrice).toLocaleString("en", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    })}
                </Text>
            </WrapperCard>
        </Container>
    );
};
export default RowForDesktop;

const Container = styled.div<{ isHaving?: boolean; background?: string }>`
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const WrapperCard = styled(Flex)`
    width: 100%;
    height: 48px;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.background};
    &:hover {
        background: #f5f5f58a;
    }
    ${Text} {
        text-align: center;
        font-size: 14px;
    }
`;
