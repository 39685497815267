import { useState } from "react";
import styled from "styled-components";
import { Tooltip, tooltipClasses } from "@mui/material";

export const LightTooltip3 = styled(({ className, ...props }) => (
    <Tooltip 
      {...props} 
      placement="top" 
      arrow 
      classes={{ popper: className }}
      enterTouchDelay={100}
    />
  ))
  (({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: 'white',
        "&::before": {
            backgroundColor: '#f5f5f5',
          }
      },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f5',
      color: '#8c8d8e',
      boxShadow: 'red',
      fontSize: 12,
      padding: 10,
      fontWeight: 500,
    },
  }));