import { useTranslation } from "@pancakeswap/localization";
import { useToast } from "@pancakeswap/uikit";
import { ToastDescriptionWithTx } from "components/Toast";
import { useCallWithMarketGasPrice } from "hooks/useCallWithMarketGasPrice";
import { useWrapped } from "hooks/useContract";
import { useUserRejected } from "hooks/useUserRejected";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { actionDispatchAssetMessage } from "state/asset/actions";
import { VestingItem } from "state/asset/type";


export const useClaim = (
    nftAddress: string,
    buys: {
        nftBalance: number,
        nftId: number,
        fullVestingMap?: VestingItem[]
    }[],
    index: number,
    account: string,
    chainId: number,
    onDismiss: any
) => {
    const { t } = useTranslation();
    const [requestedClaim, setRequestClaim] = useState(false);
    const { toastSuccess, toastError } = useToast();
    const { callWithMarketGasPrice } = useCallWithMarketGasPrice();
    const [pendingClaim, setPenddingClaim] = useState(false);
    const contractWrapped = useWrapped(nftAddress);
    const dispatch = useDispatch<AppDispatch>();
    const { handleRejected } = useUserRejected();
    const checkHarvest = buys.filter((item) => !item.fullVestingMap[index].isVested);
    const _tokenIds = checkHarvest.map((item) => (item.nftId));
    const handleClaim = useCallback(async () => {
        setPenddingClaim(true);
        try {
            const tx = await callWithMarketGasPrice(contractWrapped, "harvest", [_tokenIds, index]);
            const receipt = await tx.wait();
            if (receipt.status) {
                toastSuccess(t("Nhận lợi suất thành công"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
                setRequestClaim(false);
                setPenddingClaim(false);
                onDismiss()
                dispatch(actionDispatchAssetMessage({ messageHash: receipt.transactionHash }));

            } else {
                setRequestClaim(false);
                toastError(
                    t("Đã có lỗi xảy ra"),
                    t("Xác nhận giao dịch, và đảm bảo rằng bạn đủ token trong ví để xử lý giao dịch!")
                );
                setPenddingClaim(false);
            }
        } catch (e) {
            handleRejected(e);
            setPenddingClaim(false);
        }
    }, [_tokenIds, callWithMarketGasPrice, contractWrapped, dispatch, handleRejected, index, onDismiss, t, toastError, toastSuccess]);
    return { handleClaim, requestedClaim, pendingClaim };
};
