import { useTranslation } from "@pancakeswap/localization";
import {
    AutoRenewIcon,
    Box,
    Button,
    Flex,
    HelpIconCcqIcon,
    HelpIconCcqIcon2,
    OpenNewIconFundgo,
    Text
} from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import { LightTooltip2 } from "components/ToolTip2";
import { LightTooltip3 } from "components/ToolTip3";
import { UNIT_DATE } from "config";
import React, { useMemo } from "react";
import { ListAssestItem } from "state/asset/type";
import styled from "styled-components";
import { getBlockExploreLink } from "utils";
import { convertDate } from "utils/converDate";
import { GetNftInfo } from "../hook/fetchData";
import { useClaim } from "../hook/useClaim";
import {
    CsFlexHeader,
    CsFlexLeft,
    CsFlexRight,
    CsModal,
    CsTextModalLabel,
    CsTextModalValue,
    CsTextModalValuePrice,
    CsTextVndc,
    CustomLink,
    WrapItemMobile
} from "../styles";

interface ModalProps {
    title: React.ReactNode;
    hideCloseButton?: boolean;
    onBack?: () => void;
    onDismiss?: () => void;
    bodyPadding?: string;
    headerBackground?: string;
    windowSize: number;
    price: string;
    profit: string;
    currentyield: string;
    origininvest: number;
    interestPayDate: number;
    dayleft: number;
    account: string;
    chainId: number;
    dataUser: ListAssestItem,
}

const ProfitReceivedModal: React.FC<React.PropsWithChildren<ModalProps>> = ({
    account,
    chainId,
    price,
    profit,
    currentyield,
    origininvest,
    interestPayDate,
    dayleft,
    windowSize,
    title,
    dataUser,
    onDismiss,
    ...props
}) => {
    const { t } = useTranslation();
    function handleOpenLink(address) {
        window.open(getBlockExploreLink(address, "address", chainId));
    }
    function sAccount(dataAddress) {
        if (dataAddress?.length > 0) {
            return `${dataAddress.substring(0, 4)}...${dataAddress.substring(dataAddress.length - 4)}`;
        }
        return "";
    }
    const { handleClaim, requestedClaim, pendingClaim } = useClaim(dataUser?.nftAddress, dataUser?.buys, dataUser?.vestingMap[0].index, account, chainId, onDismiss)
    const currentYield = useMemo(() => {
        return new BigNumber(currentyield).multipliedBy(dataUser?.nftBalance).toString()
    }, [currentyield, dataUser?.nftBalance])
    const { nftInfo } = GetNftInfo(dataUser?.nftAddress, dataUser?.nftId, account, chainId)
    // const { priceAtTime, isLoading } = GetPriceAtTime( dataUser?.nftAddress, nftInfo?.holdingTime/1000, chainId)

    const investmentAmount = useMemo(() => {
        return new BigNumber(dataUser?.nftBalance).multipliedBy(nftInfo?.originalInvestment).toString()
    }, [dataUser?.nftBalance, nftInfo?.originalInvestment])

    const totalVested = useMemo(() => {
        return dataUser?.fullVestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [dataUser?.fullVestingMap])
    const totalVestingMap = useMemo(() => {
        return dataUser?.fullVestingMap?.length
    }, [dataUser?.fullVestingMap?.length])


    return (
        <CsModal title={title} onDismiss={onDismiss} {...props}>
            <Flex width="100%" flexDirection="column" alignItems="center" mt="1rem">
                <TextTitle>{t("Thông tin Chứng chỉ Quỹ")}</TextTitle>
            </Flex>
            <Box>
                {windowSize > 1000 ? (
                    <>
                        <WrapperItemHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Mệnh giá</Text>
                                <LightTooltip2 title="Mệnh giá (VND)">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <Text width="14.2%">Lợi suất</Text>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Kỳ đã nhận</Text>
                                <LightTooltip2 title="Kỳ đã nhận/Tổng kỳ">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>LS hiện tại</Text>
                                <LightTooltip2 title="Lợi suất hiện tại (VND)">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Gốc đầu tư</Text>
                                <LightTooltip2 title="Gốc đầu tư (VND)">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>Ngày trả LS</Text>
                                <LightTooltip2 title="Ngày trả lợi suất">
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </LightTooltip2>
                            </CsFlexHeader>
                            <Text width="14.2%">Ngày còn lại</Text>
                        </WrapperItemHeader>
                        <Container>
                            <WrapperCard>
                                <Text width="14.2%">
                                    {Number(price).toLocaleString("en", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })}
                                </Text>
                                <Text width="14.2%">{profit}</Text>
                                <Text width="14.2%">{totalVested}/{totalVestingMap}</Text>
                                <Text width="14.2%">
                                    {
                                        Number(currentYield).toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })}
                                </Text>
                                <Text width="14.2%">
                                    {
                                        Number(investmentAmount).toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })
                                    }
                                </Text>
                                <Text width="14.2%">{convertDate(interestPayDate * 1000)}</Text>
                                <Text width="14.2%">{dayleft} {UNIT_DATE}</Text>
                            </WrapperCard>
                        </Container>
                    </>
                ) : (
                    <>
                        <WrapItemMobile>
                            <Flex width="100%" justifyContent="space-between" alignItems="center" flexWrap="wrap">

                                <CsFlexHeaderMb width="21%" justifyContent="flex-start">
                                    <LightTooltip3 title="Mệnh giá (VND)">
                                        <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                            <Text>
                                                {Number(price).toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                })}
                                            </Text>
                                            <HelpIconCcqIcon2 />
                                        </Flex>
                                    </LightTooltip3>
                                </CsFlexHeaderMb>

                                <CsFlexHeaderMb width="21%" justifyContent="center">
                                    <LightTooltip3 title="Kỳ đã nhận/Tổng kỳ">
                                        <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                            <Text>
                                                {totalVested}/{totalVestingMap}
                                            </Text>
                                            <HelpIconCcqIcon2 />
                                        </Flex>
                                    </LightTooltip3>
                                </CsFlexHeaderMb>

                                <CsFlexHeaderMb width="21%" justifyContent="flex-end">
                                    <LightTooltip3 title="Lợi suất">
                                        <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                            <Text ml="4px">
                                                {profit}
                                            </Text>
                                            <HelpIconCcqIcon2 />
                                        </Flex>
                                    </LightTooltip3>
                                </CsFlexHeaderMb>

                                {/* <Text minWidth="21%" textAlign="center">
                                    {profit}
                                </Text> */}
                                {/* <Text textAlign="right" width="21%">
                                    {Number(price).toLocaleString("en", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })}
                                </Text> */}
                            </Flex>
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                mt="1rem"
                                alignItems="center"
                                flexWrap="wrap"
                            >
                                <CsFlexHeaderMb minWidth="33%" justifyContent="flex-start">
                                    <LightTooltip3 title="Ngày còn lại">
                                        <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                            <Text ml="4px">
                                                {dayleft} {UNIT_DATE}
                                            </Text>
                                            <HelpIconCcqIcon2 />
                                        </Flex>
                                    </LightTooltip3>
                                </CsFlexHeaderMb>

                                <CsFlexHeaderMb minWidth="33%" justifyContent="flex-start">
                                    <LightTooltip3 title="Gốc đầu tư (VND)">
                                        <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                            <Text ml="4px">
                                                {Number(investmentAmount).toLocaleString("en", {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0
                                                })}
                                            </Text>
                                            <HelpIconCcqIcon2 />
                                        </Flex>
                                    </LightTooltip3>
                                </CsFlexHeaderMb>

                                <CsFlexHeaderMb minWidth="21%" justifyContent="flex-start">
                                    <LightTooltip3 title="Ngày trả lợi suất">
                                        <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                            <Text ml="4px">
                                                {convertDate(interestPayDate * 1000)}
                                            </Text>
                                            <HelpIconCcqIcon2 />
                                        </Flex>
                                    </LightTooltip3>
                                </CsFlexHeaderMb>
                            </Flex>
                        </WrapItemMobile>
                    </>
                )}
            </Box>

            <CsFlex>
                {/* Left */}
                <CsBox>
                    <Text color="text" fontWeight="700">
                        {t("Thông tin giao dịch")}
                    </Text>
                    <Flex mt={["1rem", , "1.5rem"]} width="100%" alignItems="center" justifyContent="space-between">
                        <CsTextModalLabel>{t("Địa chỉ ví thụ hưởng")}</CsTextModalLabel>
                        <CustomLink style={{ textAlign: "right" }} onClick={() => handleOpenLink(account)}>
                            <Text fontWeight="600">{sAccount(account)}</Text>
                            <OpenNewIconFundgo />
                        </CustomLink>
                    </Flex>
                </CsBox>

                {/* Right */}
                <CsBox>
                    <CsFlexLeft width="100%">
                        <CsTextModalLabel>Ngày giao dịch</CsTextModalLabel>
                        <Flex alignItems="center">
                            <CsTextModalValue>{convertDate(Date.now())}</CsTextModalValue>
                        </Flex>
                    </CsFlexLeft>
                    <CsFlexRight width="100%">
                        <CsTextModalLabel>Nhận lợi suất (1 kỳ)</CsTextModalLabel>
                        <Flex alignItems="flex-end" flexDirection="column">
                            <Flex>
                                <CsTextModalValuePrice>
                                    {Number(currentYield).toLocaleString("en", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })}
                                </CsTextModalValuePrice>
                                <CsTextVndc fontWeight="700" ml="5px">VND</CsTextVndc>
                            </Flex>
                            {Number(currentYield) !== 0 && <Flex mt="4px">
                                <CsUnit style={{ color: "#8C8D8E", fontSize: '12px' }}>
                                    {` (Tương đương ${Number(currentYield).toLocaleString("en", {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                    })} điểm VNDC)`}
                                </CsUnit>
                            </Flex>}
                        </Flex>
                    </CsFlexRight>
                </CsBox>
            </CsFlex>
            {
                dataUser.isOder &&
                <Flex
                    style={{
                        textAlign: "center",
                        color: "red",
                        fontSize: "13px",
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "10px",
                    }}
                >Đã hết kỳ nhận lợi suất vui lòng hủy tất cả giao dịch thỏa thuận!</Flex>
            }
            <Flex width="100%" justifyContent="center" style={{ marginTop: "24px" }}>
                <CsButtonConfirm
                    onClick={handleClaim}
                    disabled={pendingClaim || requestedClaim || dataUser?.paused || dataUser?.isOder}
                    endIcon={pendingClaim ? <AutoRenewIcon spin color="textSubtle" /> : 'null'}
                >
                    {requestedClaim ?
                        t("Đã nhận lợi suất")
                        :
                        t("Nhận lợi suất")
                    }
                </CsButtonConfirm>
            </Flex>
            {dataUser?.paused === true &&
                <Flex width="100%" justifyContent="center" mt={["1rem", , , "2rem"]}>
                    <Text fontSize={["12px", , , "14px"]} color="red">{t("CCQ đang tạm khóa, vui lòng quay lại sau!")}</Text>
                </Flex>
            }
        </CsModal>
    );
};

const TextTitle = styled(Text)`
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: ${({ theme }) => theme.colors.text};
    @media screen and (max-width: 1000px) {
        font-size: 24px;
        letter-spacing: 0;
    }
`;

const CsBox = styled(Box)`
    width: 45%;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;
const CsText = styled(Text)`
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
    &:first-child {
        text-align: left;
    }
`;

const CsButtonConfirm = styled(Button)`
    height: 60px;
    font-size: 20px;
    font-weight: 700;
    min-width: 210px;
    transition: 0.5s;
    @media screen and (max-width: 1000px) {
        border-radius: 4px;
        min-width: 165px;
        font-size: 14px;
        height: 40px;
    }
`;

const CsFlex = styled(Flex)`
    justify-content: space-between;
    margin-top: 3rem;
    @media screen and (max-width: 1000px) {
        flex-direction: column;
        margin-top: 2rem;
    }
`;

const WrapperCard = styled(Flex)`
    width: 100%;
    height: 48px;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.background};
    ${Text} {
        text-align: center;
        font-size: 14px;
    }
`;

const Container = styled.div<{ isHaving?: boolean; background?: string }>`
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const WrapperItemHeader = styled(Flex)`
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    border-radius: 20px;
    margin: 24px 0 12px 0;
    align-items: center;
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.colors.textSubtle};
    }
    @media screen and (max-width: 600px) {
        padding: 2rem 25px;
    }
`;

const CsFlexHeaderMb = styled(Flex)`
    max-width: 100%;
    align-items: center;
    /* justify-content: center; */
`
const CsUnit = styled.span`
    font-size: 12px;
    @media screen and (max-width: 600px) {
        font-size: 10px;
    }
`
export default ProfitReceivedModal;
