import { AutoRenewIcon, Flex, HelpIconCcqIcon, Text } from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import { LightTooltip2 } from "components/ToolTip2";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import React, { useMemo, useState } from "react";
import { GetDataAsset, UseCoreAsset } from "state/asset";
import styled from "styled-components";
import { renderToken } from "utils/renderAddress";
import { CustomPaginate } from "views/MarketPlace/components";
import Row from "./components/Row";

interface Props {
    windowSize: number;
}
const pageSize = 7;
const TabsAsset: React.FC<React.PropsWithChildren<Props>> = ({ windowSize }) => {
    const { chainId, account } = useActiveWeb3React();
    const [itemOffset, setItemOffset] = useState(0);
    const currentTime = Date.now()
    const token = renderToken(chainId);
    UseCoreAsset(token?.address, account, chainId);
    const { listUserBalance, isLoading } = GetDataAsset();

    const listAssetItem = useMemo(() => {
        return [...listUserBalance]
    }, [listUserBalance])
    const sortList = [...listAssetItem].sort((a, b) => b?.holdingDate - a?.holdingDate);

    const totalAmount = useMemo(() => {
        return sortList.reduce((acc, item) => {
            return acc + Number(item.nftBalance);
        }, 0);
    }, [sortList])

    const totalPrice = useMemo(() => {
        return sortList.reduce((acc, item) => {
            return acc + Number(item.denominations) * Number(item?.nftBalance);
        }, 0);
    }, [sortList])
    const totalYieldReceive = useMemo(() => {
        return sortList.reduce((acc, item) => {
            return acc + (Number(item?.vestingMap[0]?.vestAmount || 0) * Number(item?.nftBalance));
        }, 0);
    }, [sortList])

    const total = sortList.length;
    return (
        <Flex width="100%" flexDirection="column">
            <WrapperTotal>
                <Flex width="100%">
                    <Text fontSize="20px" color="#8C8D8E">Tổng mệnh giá</Text>
                    {new BigNumber(totalPrice).isNaN() ?
                        <Text>0</Text>
                        :
                        <Flex alignItems="center">
                            <Text color="primaryBright" fontSize="28px">{totalPrice.toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text>
                            <Text fontSize="28px" bold ml="5px">VND</Text>
                        </Flex>
                    }
                </Flex>
                <Flex width="100%">
                    <Text fontSize="20px" color="#8C8D8E">Tổng lợi suất sắp nhận</Text>
                    {new BigNumber(totalYieldReceive).isNaN() ?
                        <Text>0</Text>
                        :
                        <Flex alignItems="center">
                            <Text color="primaryBright" fontSize="28px">{totalYieldReceive.toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</Text>
                            <Text fontSize="28px" bold ml="5px">VND</Text>
                        </Flex>
                    }
                </Flex>
            </WrapperTotal>
            {windowSize > 768 && (
                <WrapperTableHeader>
                    <CsFlexHeader>
                        <Text mr='0.2rem'>
                            SL ({totalAmount.toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 })})
                        </Text>
                    </CsFlexHeader>
                    <CsFlexHeader>
                        <Text mr='0.2rem'>Mệnh giá</Text>
                        <LightTooltip2 title="Mệnh giá (VND)">
                            <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                        </LightTooltip2>
                    </CsFlexHeader>
                    <CsFlexHeader>
                        <Text mr='0.2rem'>Kỳ nhận LS</Text>
                        <LightTooltip2 title="Kỳ nhận lợi suất">
                            <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                        </LightTooltip2>
                    </CsFlexHeader>
                    <CsFlexHeader>
                        <Text mr='0.2rem'>Kỳ đã nhận</Text>
                        <LightTooltip2 title="Kỳ đã nhận/Tổng kỳ">
                            <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                        </LightTooltip2>
                    </CsFlexHeader>
                    <CsFlexHeader>
                        <Text mr='0.2rem'>LS/năm</Text>
                        <LightTooltip2 title="Lợi suất/năm">
                            <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                        </LightTooltip2>
                    </CsFlexHeader>
                    <CsFlexHeader>
                        <Text mr='0.2rem'>Ngày trả LS</Text>
                        <LightTooltip2 title="Ngày trả lợi suất">
                            <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                        </LightTooltip2>
                    </CsFlexHeader>
                    <Text width="9.4%">Ngày còn lại</Text>
                    <CsFlexHeader>
                        <Text mr='0.2rem'>LSSN</Text>
                        <LightTooltip2 title="Lợi suất sắp nhận (VND)">
                            <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                        </LightTooltip2>
                    </CsFlexHeader>
                    <CsFlexHeader>
                        <Text mr='0.2rem'>Tổng MG</Text>
                        <LightTooltip2 title="Tổng mệnh giá (VND)">
                            <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                        </LightTooltip2>
                    </CsFlexHeader>
                    <Text width="15%" />
                </WrapperTableHeader>
            )}
            {
                isLoading ?
                    <Flex width="100%" justifyContent="center" mt="1.5rem">
                        <AutoRenewIcon spin color="textSubtle" />
                    </Flex>
                    :
                    <>
                        {sortList.length === 0 ? (
                            <Flex width="100%" justifyContent="center" alignItems="center" mt="1.5rem">
                                <Text>Không có dữ liệu</Text>
                            </Flex>
                        ) : (
                            <>
                                {sortList.splice(itemOffset, pageSize).map((data, key) => (
                                    <Row
                                        item={data}
                                        windowSize={windowSize}
                                        account={account}
                                        chainId={chainId}
                                        vestingMap={data?.vestingMap}
                                        currentTime={currentTime}
                                        indexRow={key}
                                    />
                                ))}

                                <CustomFlex>
                                    {sortList.length !== 0 &&
                                        <CustomPaginate
                                            total={total}
                                            pageSize={pageSize}
                                            setItemOffset={setItemOffset}
                                        />
                                    }
                                </CustomFlex>
                            </>
                        )}
                    </>
            }
        </Flex>
    );
};

const CsFlexHeader = styled(Flex)`
    max-width: 100%;
    align-items: center;
    justify-content: center;
    width: 9.4%;
`
const WrapperTotal = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 100px;
    margin-top: 60px;
    align-items: center;
    @media screen and (max-width: 1200px){
        gap: 60px;
        ${Text}{
            font-size: 22px;
        }
    }
    @media screen and (max-width: 1000px){
        gap: 60px;
        margin-top: 30px;
        margin-bottom: 20px;
        ${Text}{
            font-size: 18px;
        }
    }
    @media screen and (max-width: 767px){
        gap: 20px;
        margin-top: 30px;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        ${Text}{
            font-size: 16px;
        }
    }
    @media screen and (max-width: 600px){
        ${Text}{
            font-size: 14px;
        }
    }
    h6 {
        font-size: 1.25rem;
        color: #8c8d8e;
        font-weight: 500;
    }
    p {
        color: #f9a138;
        font-size: 1.75rem;
        font-weight: 500;
        span {
            color: #02111b;
            font-weight: 600;
        }
    }
    ${Flex} {
        justify-content: space-between;
    }
`;

const WrapperTableHeader = styled(Flex)`
    width: 100%;
    height: 100%;
    position: relative;
    flex-direction: row;
    border-radius: 20px;
    margin: 40px 0 0px 0;
    padding: 0 26px;
    align-items: center;
    justify-content: space-between;
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.colors.textSubtle};
    }
    @media screen and (max-width: 600px) {
        padding: 2rem 25px;
    }
`;
const CustomFlex = styled(Flex)`
    margin-bottom: 1.5rem;
    width: 100%;
    justify-content: center;
    margin-top:1rem;
    .pagination {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        @media screen and (max-width: 600px) {
            width: 100%;
        }
        * {
            list-style-type: none;
        }
    }
    .page-item {
        margin: 0 0.375rem;
        &:first-child,
        &:last-child {
            a {
                &:hover {
                    color: #13486b;
                    border: none !important;
                }
            }
        }
        &:first-child,
        &:last-child {
            a {
                border: none;
                background: none !important;
            }
        }
        &:last-child.disabled,
        &:first-child.disabled {
            cursor: not-allowed;
        }
    }
    .page-link {
        width: 32px;
        height: 32px;
        display: flex;
        font-size: 14px !important;
        background: #fff;
        align-items: center;
        justify-content: center;
        border-radius: 8px !important;
        color: #02111b;
        border: 1px solid #efefef;
        @media screen and (max-width: 768px) {
            padding: 12px;
        }
        &:focus {
            box-shadow: none !important;
        }
        &:hover {
            background: #13486b;
            color: #fff;
            border: 1px solid #13486b !important;
        }
    }
    .page-item.disabled .page-link {
        background: ${({ theme }) => theme.colors.disabled};
        cursor: not-allowed !important;
        opacity: 0.7;
        pointer-events: none;
    }
    .page-item.active .page-link {
        background: #13486b;
        color: #fff;
        border: none;
    }
`;


export default TabsAsset;
