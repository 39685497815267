import { Button, Flex, NoticeIcon, Text, useTooltip } from "@pancakeswap/uikit";
import { LightTooltip } from "components/ToolTip";
import { UNIT_DATE } from "config";
import React, { useMemo } from "react";
import { Items } from "state/MarketPlace/type";
import styled from "styled-components";
import { CsItem, CsItemSmall, CsItemWarning } from "../style";

interface optionsProps {
    handleChoose: (id: number, nftAddress: string, buys) => void;
}

type HandleProps = Items & optionsProps;

const Item: React.FC<HandleProps> = ({
    amount,
    price,
    interVestTerm,
    handleChoose,
    id,
    interVestTermRate,
    paydayNextProfit,
    unitPrice,
    dayDiff,
    vestingMap,
    paused,
    nftAddress,
    buys
}) => {
    const totalVested = useMemo(() => {
        return vestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [vestingMap])
    const totalVestingMap = useMemo(() => {
        return vestingMap?.length
    }, [vestingMap])
    const { targetRef, tooltip, tooltipVisible } = useTooltip(
        <CsTextTootip style={{ color: "red" }}>CCQ đang tạm khóa, vui lòng quay lại sau!</CsTextTootip>,
        { placement: "top-end", tooltipOffset: [20, 10] }
    );
    return (
        <Container>
            <WrapperCard>
                <CsItemSmall>
                    {Number(amount).toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                </CsItemSmall>
                <CsItemSmall>
                    {Number(price).toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                </CsItemSmall>
                <CsItemSmall>{totalVested}/{totalVestingMap}</CsItemSmall>
                <CsItemSmall>{`${interVestTerm} tháng`}</CsItemSmall>
                <CsItemSmall style={{ display: "flex", justifyContent: "center" }}>
                    {paydayNextProfit}
                </CsItemSmall>
                <CsItemSmall>{`${dayDiff} ${UNIT_DATE}`}</CsItemSmall>
                <CsItemSmall>{interVestTermRate}%</CsItemSmall>
                <CsItemSmall>
                    {Number(unitPrice).toLocaleString("en", { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                </CsItemSmall>
                <CsItem />
                <>
                    <CsButtonChoose
                        disabled={paused}
                        className='buttonChoose'
                        onClick={() => {
                            handleChoose(id, nftAddress, buys)
                        }}
                    >Chọn
                    </CsButtonChoose>
                </>
                <CsItemWarning>
                    {/* { paused &&
                        <LightTooltip title="CCQ đang tạm khóa, vui lòng quay lại sau!">
                            <Flex justifyContent="center" style={{cursor: 'pointer'}}> <WarningIcon2 /> </Flex>
                        </LightTooltip>
                    } */}
                    {paused &&
                        <>
                            <ReferenceElement ref={targetRef}>
                                <NoticeIcon />
                            </ReferenceElement>
                            <>{tooltipVisible && tooltip}</>
                        </>
                    }
                </CsItemWarning>
            </WrapperCard>
        </Container>
    );
};

export default Item;

const CsButtonChoose = styled(Button) <{ disable: boolean }>`
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    min-width: 112px;
    height: 40px;
    &.buttonChoose{
        &:hover:not(:disabled){
            background: #447290;
            color: ${({ theme }) => theme.colors.white};
        }
        &:hover{
            background-color: var(--colors-backgroundDisabled);
            border-color: var(--colors-backgroundDisabled);
            color: ${({ theme }) => theme.colors.textSubtle};
        }
    }
`;

const Container = styled.div<{ isHaving?: boolean; background?: string }>`
    width: 100%;
    padding: 0px 6px 0px 6px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center top;
`;

const WrapperCard = styled(Flex)`
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    align-items: center;
    background: rgba(255, 255, 255, 0.62);
    &::after {
        content: "";
        position: absolute;
        left: 19%;
        width: 1px;
        height: 100%;
        background: ${({ theme }) => theme.colors.border2};
    }
    &::before {
        content: "";
        position: absolute;
        right: 33.5%;
        width: 1px;
        height: 100%;
        background: ${({ theme }) => theme.colors.border2};
    }
    &:hover {
        background: ${({ theme }) => theme.colors.white};
    }
    button {
        transition: 0.5s;
        &:hover {
            background: ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.white};
            border: 1px solid transparent;
        }
    }
    ${CsItemSmall} {
        height: 100%;
        align-items:center
    }
`;
const CsTextTootip = styled(Text)`
    font-size: 14px;
    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
`;
const ReferenceElement = styled.div`
    display: inline-block;
    cursor: pointer;
`;