import React, { useState, useEffect } from "react";
import multicall from "utils/multicall";
import ccqWrappedAbi from "config/abi/ccqWrappedAbi.json";
import BigNumber from "bignumber.js";

export interface NftInfo {
    publisher: string,
    name: string,
    price: string,
    issueDate: number,
    expireDate: number,
    intervestTerm: number,
    totalSupply: number,
    originalInvestment: string,
    yieldCurrent: string,
    profit: string,
    nextInterestPaymentDate: number,

}
export const GetNftInfo = (nftAddress: string, sellerAddress: string, nftId: number, account: string, chainId: number) => {
    const [nftInfo, setNftInfo] = useState({
        publisher: "",
        name: "",
        price: "0",
        issueDate: 0,
        expireDate: 0,
        intervestTerm: 0,
        totalSupply: 0,
        profit: "0",
        nextInterestPaymentDate: 0,
    })
    const [isLoading, setLoading] = useState(false)
    useEffect(() => {
        const getDataNft = async () => {
            try {
                setLoading(true)
                const calls = [
                    {
                        address: nftAddress,
                        name: 'etfInfor',
                        params: []
                    },
                    {
                        address: nftAddress,
                        name: 'intervestTermRate',
                        params: []
                    },
                    {
                        address: nftAddress,
                        name: 'getMyVestList',
                        params: [nftAddress, nftId]
                    }
                ]
                const [resultEtfInfo, resultProfit, resultVestingMap] = await multicall(ccqWrappedAbi, calls, chainId);
                const vestingMap = resultVestingMap[0]?.find((item) => item?.isVested === false)

                setNftInfo(
                    {
                        publisher: resultEtfInfo?.publisher,
                        name: resultEtfInfo?.name,
                        price: new BigNumber(resultEtfInfo?.price?.toString()).dividedBy(1E18).toString(),
                        issueDate: Number(new BigNumber(resultEtfInfo?.issueDate?.toString()).toString()),
                        expireDate: Number(new BigNumber(resultEtfInfo?.expireDate?.toString()).toString()),
                        intervestTerm: Number(new BigNumber(resultEtfInfo?.intervestTerm?.toString()).toString()) === 92 ? 3 : 6,
                        totalSupply: Number(new BigNumber(resultEtfInfo?.totalSupply?.toString()).toString()),
                        profit: Number(new BigNumber(resultEtfInfo?.intervestTerm?.toString()).toString()) === 92 ? "8" : "9.2",
                        nextInterestPaymentDate: Number(vestingMap?.vestDate),
                    }
                )
                setLoading(false)
            }
            catch (error) {
                setLoading(false)
            }
        }
        if (account?.length > 0) {
            getDataNft()
        }
    }, [nftId, chainId, account, nftAddress, sellerAddress])
    return { nftInfo, isLoading }
}
