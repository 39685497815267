import { useTranslation } from "@pancakeswap/localization";
import { Button, Flex, Input, MinusIcon, PlusIcon, Text } from "@pancakeswap/uikit";
import { UNIT_DATE } from "config";
import React, { useMemo } from "react";
import { Items } from "state/MarketPlace/type";
import styled from "styled-components";
import { CsItem, CsItemSmall } from "views/MarketPlace/style";

interface PropsItem {
    index: number;
    buyAmount: number;
    listItem: Items;
    handleChangeAmountBuy: (amount, id, nftAddress, buys) => void;
    handleCancel: (id) => void;
}

const ItemBuy: React.FC<PropsItem> = ({ buyAmount, listItem, handleChangeAmountBuy, handleCancel }) => {
    const { t } = useTranslation();
    const handleChangeInput = (e) => {
        const { value } = e.target;
        if (/^\d+$/.test(value) || value === "") {
            let convertNumber = value;
            if (Number(convertNumber) < 1) {
                convertNumber = '';
            } else if (convertNumber > Number(listItem?.amount)) {
                convertNumber = listItem?.amount;
            }
            handleChangeAmountBuy(Number(convertNumber), listItem.id, listItem.nftAddress, listItem.buys);
        }
    };

    const totalVested = useMemo(() => {
        return listItem?.vestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [listItem?.vestingMap])

    const totalVestingMap = useMemo(() => {
        return listItem?.vestingMap?.length
    }, [listItem?.vestingMap])

    return (
        <Container>
            <WrapperCard>
                <CsItemSmall>
                    {Number(listItem?.amount).toLocaleString("en", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    })}
                </CsItemSmall>
                <CsItemSmall>
                    {Number(listItem?.price).toLocaleString("en", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    })}
                </CsItemSmall>
                <CsItemSmall>{totalVested}/{totalVestingMap}</CsItemSmall>
                <CsItemSmall>{`${listItem?.interVestTerm} tháng`}</CsItemSmall>
                <CsItemSmall style={{ display: "flex", justifyContent: "center" }}>
                    {listItem?.paydayNextProfit}
                </CsItemSmall>
                <CsItemSmall>{`${listItem.dayDiff} ${UNIT_DATE}`}</CsItemSmall>
                <CsItemSmall>{listItem?.interVestTermRate}%</CsItemSmall>
                <CsItemSmall>
                    {Number(listItem?.unitPrice).toLocaleString("en", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    })}
                </CsItemSmall>
                <CsItem>
                    <Flex width="100%" justifyContent="center">
                        <WrapCount>
                            <ButtonQuanlity
                                disabled={Number(buyAmount) <= 1}
                                onClick={() => handleChangeAmountBuy(Number(buyAmount) - 1, listItem.id, listItem.nftAddress, listItem.buys)}
                            >
                                <MinusIcon />
                            </ButtonQuanlity>
                            <CustomInput
                                type="text"
                                scale="lg"
                                inputMode="numeric"
                                value={buyAmount}
                                placeholder="0"
                                onChange={handleChangeInput}
                            />
                            <ButtonQuanlity
                                onClick={() => handleChangeAmountBuy(Number(buyAmount) + 1, listItem.id, listItem.nftAddress, listItem.buys)}
                                disabled={Number(buyAmount) >= Number(listItem?.amount)}
                            >
                                <PlusIcon />
                            </ButtonQuanlity>
                        </WrapCount>
                    </Flex>
                </CsItem>
                <CsItem>
                    <CsButtonCancel onClick={() => handleCancel(listItem.id)}>{t("Huỷ")}</CsButtonCancel>
                </CsItem>
            </WrapperCard>
        </Container>
    );
};

export default ItemBuy;

const CustomInput = styled(Input)`
    background: none;
    color: ${({ theme }) => theme.colors.white};
    padding: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    border: none;
    box-shadow: none;
    font-size: 16px;
    border-radius: 0;
    &::placeholder {
        color: ${({ theme }) => theme.colors.white};
    }
    @media screen and (max-width: 1000px) {
        font-size: 14px;
    }
`;

const ButtonQuanlity = styled(Button)`
    border: 1px solid transparent;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    box-shadow: none;
    padding: 4px;
    height: 24px;
    width: 24px;
`;

const WrapCount = styled(Flex)`
    gap: 6px;
    align-items: center;
    background: ${({ theme }) => theme.colors.success3};
    border-radius: 4px;
    width: 104px;
    height: 34px;
    padding: 4px;
`;

const CsButtonCancel = styled(Button)`
    border: 1px solid ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.red};
    background: transparent;
    border-radius: 4px;
    min-width: 112px;
    height: 40px;
`;

const Container = styled.div<{ isHaving?: boolean; background?: string }>`
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center top;
`;

const WrapperCard = styled(Flex)`
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background: rgba(255, 255, 255, 0.62);
    &::after {
        content: "";
        position: absolute;
        left: 19%;
        width: 1px;
        height: 100%;
        background: ${({ theme }) => theme.colors.border2};
    }
    &::before {
        content: "";
        position: absolute;
        right: 33.5%;
        width: 1px;
        height: 100%;
        background: ${({ theme }) => theme.colors.border2};
    }
    ${CsItemSmall}{
        height: 70px;
        align-items: center;
    }
`;
