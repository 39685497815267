export const ExchangeToken = ({ price }) => {
    return (
        <span style={{ color: "#8C8D8E", fontSize: "12px", }}>
            {` (Tương đương ${Number(price).toLocaleString("en", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
            })} điểm VNDC)`}
        </span>
    )
};
// flexDirection="column" alignItems="flex-end"
