import React, { useCallback, useState } from "react";
import { axiosClient } from "config/htttp";
import fileDownload from "js-file-download";
import { useToast } from "@pancakeswap/uikit";

export const useDownload = (raw: any) => {
    const [penddingDowload, setPenddingDownLoad] = useState(false)
    const { toastError, toastSuccess } = useToast();
    const handleDownload = useCallback(async () => {
        try {
            setPenddingDownLoad(!false)
            const response = await axiosClient.post("/pdf-generator/etf-transfer", raw, {
                responseType: "arraybuffer",
                headers: { "Content-Type": "application/json", 'Accept': '*' }
            });
            fileDownload(response.data, "hop_dong_chuyen_nhuong.pdf");
            toastSuccess("Tải xuống thành công.");
        } catch (error) {
            toastError("Tải xuống thất bại. Vui lòng thử lại.");
        } finally {
            setPenddingDownLoad(false)
        }
    }, [raw, toastError, toastSuccess])
    return { penddingDowload, handleDownload }
}