import { useTranslation } from "@pancakeswap/localization";
import { Button, Flex, Text } from "@pancakeswap/uikit";
import GetWindowSize from "@pancakeswap/uikit/src/util/getWindowSize";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import {
    selectCurrentYield,
    selectDenominations,
    selectNextInterestPaymentDate,
    selectPaymentPeriod
} from "state/MarketPlace/actions";
import styled from "styled-components";

import Select, { OptionProps } from "components/Select/Select";

const HeaderSort = () => {
    const { t } = useTranslation();
    const windowSize = GetWindowSize();
    const dispatch = useDispatch<AppDispatch>();
    // select mệnh giá
    const handleSelectDenominations = (option: OptionProps): void => {
        dispatch(selectDenominations({ denominations: Number(option?.value) }));
    };

    // select Kỳ trả lợi suất
    const handeleSelectPaymentPeriod = (option: OptionProps): void => {
        dispatch(selectPaymentPeriod({ paymentPeriod: Number(option?.value) }));
    };

    // Số ngày đến kì trả lợi suất
    const handleSelectNextInterestPaymentDate = (option: OptionProps): void => {
        dispatch(selectNextInterestPaymentDate({ nextInterestPaymentDate: Number(option?.value) }));
    };

    // select Lợi suất kì này`
    const handleSelectCurrentYield = (option: OptionProps): void => {
        dispatch(selectCurrentYield({ currentYield: Number(option?.value) }));
    };

    return (
        <>
            {windowSize > 1000 && (
                <Flex
                    padding=" 0 16px"
                    mt={["0", , "1rem"]}
                    mb="1rem"
                    width="100%"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection={["column", , , "row"]}
                >
                    <CsFlexSort
                        justifyContent="flex-start"
                        padding={["12px 0", , , "0"]}
                        alignItems="center"
                        width={["100%", , , "24%"]}
                    >
                        <Text textAlign="right" fontSize={["12px", , "14px"]} mr="10px">
                            {t(`Mệnh giá`)}
                        </Text>
                        <Select
                            options={[
                                {
                                    label: t("Toàn bộ"),
                                    value: 0
                                },
                                {
                                    label: t("100,000"),
                                    value: 100000
                                }
                            ]}
                            onOptionChange={handleSelectDenominations}
                            defaultOptionIndex={0}
                        />
                    </CsFlexSort>

                    <CsFlexSort
                        justifyContent="flex-start"
                        padding={["12px 0", , , "0"]}
                        alignItems="center"
                        width={["100%", , , "24%"]}
                    >
                        <Text textAlign="right" fontSize={["12px", , "14px"]} mr="10px">
                            {t(`Kỳ trả lợi suất`)}
                        </Text>
                        <Select
                            options={[
                                {
                                    label: t("Toàn bộ"),
                                    value: 0
                                },
                                {
                                    label: t("3 tháng"),
                                    value: 3
                                },
                                {
                                    label: t("6 tháng"),
                                    value: 6
                                }
                            ]}
                            onOptionChange={handeleSelectPaymentPeriod}
                            defaultOptionIndex={0}
                        />
                    </CsFlexSort>

                    <CsFlexSort
                        justifyContent="center"
                        padding={["12px 0", , , "0"]}
                        alignItems="center"
                        width={["100%", , , "27%"]}
                    >
                        <Text textAlign="right" fontSize={["12px", , "14px"]} mr="10px">
                            Số ngày đến <br /> kỳ trả lợi suất
                        </Text>
                        <Select
                            options={[
                                {
                                    label: t("Toàn bộ"),
                                    value: 0
                                },
                                {
                                    label: t("Dưới 10 ngày"),
                                    value: 1
                                },
                                {
                                    label: t("10 - 30 ngày"),
                                    value: 2
                                },
                                {
                                    label: t("30 - 60 ngày"),
                                    value: 3
                                },
                                {
                                    label: t("Trên 60 ngày"),
                                    value: 4
                                }
                            ]}
                            onOptionChange={handleSelectNextInterestPaymentDate}
                            defaultOptionIndex={0}
                        />
                    </CsFlexSort>

                    <CsFlexSort
                        justifyContent="flex-end"
                        padding={["12px 0", , , "0"]}
                        alignItems="center"
                        width={["100%", , , "25%"]}
                    >
                        <Text textAlign="right" fontSize={["12px", , "14px"]} mr="10px">
                            {t(`Lợi suất kỳ này`)}
                        </Text>
                        <Select
                            options={[
                                {
                                    label: t("Toàn bộ"),
                                    value: 0
                                },
                                {
                                    label: t("8%"),
                                    value: 8
                                },
                                {
                                    label: t("9.2%"),
                                    value: 9.2
                                }
                            ]}
                            onOptionChange={handleSelectCurrentYield}
                            defaultOptionIndex={0}
                        />
                    </CsFlexSort>
                </Flex>
            )}
        </>
    );
};

export default HeaderSort;

const CsFlexSort = styled(Flex)`
    /* justify-content: center; */
`;
