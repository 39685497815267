import styled from "styled-components";
import { Tooltip, tooltipClasses } from "@mui/material";

export const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip placement="top" {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: 'white',
        "&::before": {
            backgroundColor: 'white',
            // border: "1px solid red"
          }
      },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: '#8c8d8e',
      boxShadow: '#8c8d8e',
      fontSize: 14,
      padding: 10,
      fontWeight: 500,
      // border: "1px solid red"
    },
  }));