import React, { useMemo } from "react";
import { AllowanceItem, ItemWithVestingMap, Items } from "state/Order/type";
import styled from "styled-components";
import { Button, Flex, HelpIconCcqIcon2, OpenNewIconFundgo, PlayIcon, Text, useModal } from "@pancakeswap/uikit";
import { convertDate } from "utils/converDate";
import { useTranslation } from "@pancakeswap/localization";
import { LightTooltip3 } from "components/ToolTip3";
import { renderStatus } from "utils/renderStatus";
import { getBlockExploreLink } from "utils";
import { LightTooltip2 } from "components/ToolTip2";
import { CustomLink } from "./styles";
import DetailModal from "./ModalDetail";
import ConfirmModalPC from "./ConfirmModalPC";
import CancelModal from "./ModalCancel";

interface Props {
    item: ItemWithVestingMap,
    account: string,
    chainId: number,
    windowSize: number,
    indexRow: number,
    listAllowance: AllowanceItem[],
    balance: string,
    tokenAddress: string
}
const Row: React.FC<Props> = ({
    item,
    account,
    chainId,
    windowSize,
    indexRow,
    listAllowance,
    balance,
    tokenAddress
}) => {
    const { t } = useTranslation()
    function handleOpenLink(address) {
        window.open(getBlockExploreLink(address, "address", chainId));
    }
    function sAccount(dataAddress) {
        if (dataAddress) {
            return `${dataAddress.substring(0, 4)}...${dataAddress.substring(dataAddress.length - 4)}`;
        }
        return "";
    }
    const itemAllowance = listAllowance.find((data) => data?.nftAddress?.toLowerCase() === item?.nftAddress?.toLowerCase())

    const [openDetailModal] = useModal(
        <DetailModal
            handleOpenLink={handleOpenLink}
            sAccount={sAccount}
            item={item}
            account={account}
            chainId={chainId}
        />
    );
    const [onConfirmModal] = useModal(
        <ConfirmModalPC
            handleOpenLink={handleOpenLink}
            sAccount={sAccount}
            title="Xác nhận giao dịch"
            item={item}
            account={account}
            chainId={chainId}
            itemAllowance={itemAllowance}
            balance={balance}
            tokenAddress={tokenAddress}
        />,
        true,
        true,
        `modal_confirm_${indexRow}`
    );
    const [onCancelModal] = useModal(
        <CancelModal
            handleOpenLink={handleOpenLink}
            sAccount={sAccount}
            title="Thông tin Chứng chỉ Quỹ"
            item={item}
            account={account}
            chainId={chainId}
        />,
        true,
        true,
        `modal_cancel_${indexRow}`
    );

    const totalVested = useMemo(() => {
        return item?.vestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [item?.vestingMap])
    const totalVestingMap = useMemo(() => {
        return item?.vestingMap?.length
    }, [item?.vestingMap?.length])
    return (
        <>
            {windowSize > 1000 ?
                <WrapItem>
                    <CsItemSmall>
                        <CsText isBuy={item?.isSelling}>
                            {item?.isSelling ? t(`Bán`) : t(`Mua`)}
                        </CsText>
                    </CsItemSmall>
                    <CsItem>
                        <Text color="text">{convertDate(item?.dateTrading * 1000)}</Text>
                    </CsItem>
                    <CsItem>
                        <Text color="text">{totalVested}/{totalVestingMap}</Text>
                    </CsItem>
                    <CsItemSmall>
                        <Text color="text">{item?.amountTrading}</Text>
                    </CsItemSmall>
                    <CsItem>
                        <Text color="text">
                            {Number(item?.price).toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })}
                        </Text>
                    </CsItem>
                    <CsItem>
                        <Text color="text">Đang chờ xác nhận</Text>
                    </CsItem>
                    <CsItem
                        onClick={() => handleOpenLink(item?.addressTrading)}
                    >
                        <LightTooltip2 title={item?.addressTrading}>
                            <div style={{ cursor: 'pointer' }}>
                                <Text>{sAccount(item?.addressTrading)}</Text>
                            </div>
                        </LightTooltip2>
                        <OpenNewIconFundgo />
                    </CsItem>
                    <CsItem>
                        <CsButtonConfirm>
                            {item?.isSelling && (
                                <CsButtonCancel onClick={onCancelModal}>
                                    {t("Huỷ")}
                                </CsButtonCancel>
                            )}
                            {item?.isSelling === false && (
                                <CsButtonConfirmMobile onClick={onConfirmModal}>
                                    {t("Tuỳ chọn")}
                                </CsButtonConfirmMobile>
                            )}
                        </CsButtonConfirm>
                    </CsItem>
                </WrapItem>
                :
                <WrapItemMobile>
                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        mt="0.5rem"
                        alignItems="center"
                        flexWrap="wrap"
                    >
                        <Flex width="30%">
                            <PlayIcon
                                width="20px"
                                onClick={openDetailModal}
                                style={{ cursor: "pointer" }}
                            />
                            <CsText isBuy={item?.isSelling}>{item?.isSelling ? t(`Bán`) : t(`Mua`)}</CsText>
                        </Flex>

                        <CsFlexHeader width="30%">
                            <LightTooltip3 title="Số lượng">
                                <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                    <Text fontSize="11px" color="textSubtle">SL:</Text>
                                    <Text mr='4px' ml="4px">
                                        {item?.amountTrading}
                                    </Text>
                                    <HelpIconCcqIcon2 />
                                </Flex>
                            </LightTooltip3>
                        </CsFlexHeader>

                        <CsFlexHeader width="30%">
                            <LightTooltip3 title="Tổng giá trị giao dịch">
                                <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                    <Text fontSize="11px" color="textSubtle">GT:</Text>
                                    <Text mr='4px' ml="4px">
                                        {Number(item?.price).toLocaleString("en", {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 0
                                        })}
                                    </Text>
                                    <HelpIconCcqIcon2 />
                                </Flex>
                            </LightTooltip3>
                        </CsFlexHeader>
                        {/*
                        <Text width="25%">
                            {Number(item?.price).toLocaleString("en", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0
                            })}
                        </Text> */}

                        <Text minWidth="30%" style={{ fontWeight: "600" }}>
                            {" "}
                            {renderStatus(item?.isSelling)}
                        </Text>
                        <CustomLink
                            style={{ textAlign: "right" }}
                            width="25%"
                            onClick={() => handleOpenLink(item?.addressTrading)}
                        >
                            <LightTooltip2 title={item?.addressTrading}>
                                <div style={{ cursor: 'pointer' }}>
                                    <Text>{sAccount(item?.addressTrading)}</Text>
                                </div>
                            </LightTooltip2>
                            <OpenNewIconFundgo />
                        </CustomLink>
                        <CsWrapBtn>
                            {item?.isSelling ?
                                <CsButtonCancel onClick={onCancelModal}>
                                    {t("Huỷ")}
                                </CsButtonCancel>
                                :
                                <CsButtonConfirmMobile onClick={onConfirmModal}>
                                    {t("Tùy chọn")}
                                </CsButtonConfirmMobile>
                            }
                        </CsWrapBtn>
                    </Flex>
                </WrapItemMobile>
            }
        </>
    )
}
export default Row

const WrapItem = styled(Flex)`
    width: 100%;
    height: 80px !important;
    height: 100%;
    padding: 26px;
    align-items: center;
    position: relative;
    flex-direction: row;
    border-radius: 20px;
    margin-top: 24px;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.colors.textSubtle};
    ${Text} {
        text-align: center;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.04em;
    }
`;

const CsItem = styled(Flex)`
    width: 13.3%;
    height: 100%;
    align-items: center;
    justify-content: center;
`
const CsItemSmall = styled(Flex)`
    width: 10%;
    height: 100%;
    align-items: center;
    justify-content: center;
`
const CsText = styled(Text) <{ isBuy: boolean }>`
    color: ${({ theme, isBuy }) => (isBuy ? theme.colors.red : theme.colors.success2)};
    font-weight: 700;
`;
const CsButtonConfirm = styled(Flex)`
    width: 80%;
    @media screen and (max-width: 1000px) {
        width: 100%;
        margin-top: 12px;
        justify-content: center;
    }
`;

const CsButtonCancel = styled(Button)`
    width: 170px;
    height: 40px;
    padding: 6px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.red};
    transition: 0.5s;
    @media screen and (max-width: 1024px) {
        font-size: 12px;
        padding: 6px;
        width: 130px;
        height: 36px;
    }
    @media screen and (max-width: 768px){
        width: 100px;
        height: 36px;
    }
`;
const CsButtonConfirmMobile = styled(Button)`
    width: 170px;
    height: 40px;
    padding: 6px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    transition: 0.5s;
    @media screen and (max-width: 1024px) {
        padding: 6px;
        font-size: 12px;
        width: 130px;
        height: 36px;
    }
    @media screen and (max-width: 600px){
        width: 100px;
    }
`;
const WrapItemMobile = styled.div`
    margin-top: 16px;
    border-radius: 4px;
    padding: 0 12px 12px;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.background};
    @media screen and (max-width: 767px){
        margin-top: 0;
    }
    ${Text} {
        margin: 10px 0;
        font-size: 12px;
        font-weight: 500;
        &:last-child {
            text-align: right;
        }
    }
`;
const CsWrapBtn = styled(Flex)`
    justify-content: center;
    @media screen and (min-width: 767px){
        width: 25%;
    }
`
const CsFlexHeader = styled(Flex)`
    max-width: 100%;
    align-items: center;
    justify-content: center;
`